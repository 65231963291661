import React,{useState,useEffect} from "react";
import Styles from "../Midterm.module.scss";

const StudentInfo = ({ info }) => {
  // console.log("info is ", info);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  return (
    <>
      <section className={Styles.maintitle}>
        REPORT CARD OF ACADEMIC
        {screenSize && screenSize?.width >= 500 ? (<>&nbsp;</>):(<>&nbsp;&nbsp;</>)}SESSION 2023 - 2024
      </section>
      <section className={Styles.studentInfo}>
        <div style={{ display: "flex" }}>
          <div className={Styles.info}>
            <span className={Styles.infoDetailLeft}>NAME</span>
            <span className={Styles.infoDataLeft}>: {info.student_name} </span>
            <br />
            <span className={Styles.infoDetailLeft}>FATHER'S NAME</span>
            <span className={Styles.infoDataLeft}>: {info.father_name}</span>
            <br />
            <span className={Styles.infoDetailLeft}>MOTHER'S NAME</span>
            <span className={Styles.infoDataLeft}>: {info.mother_name}</span>
            <br />
          </div>
          <div className={Styles.info1}>
            <span className={Styles.infoDetailRight}>GRADE</span>:{" "}
            {info.student_grade}
            <br />
            <span className={Styles.infoDetailRight}>DATE OF BIRTH</span>:{" "}
            {info.dob}
            <br />
            <span className={Styles.infoDetailRight}>ADMISSION NO.</span>:{" "}
            {info.admission_no}
            <br />
          </div>
        </div>
        <div style={{ paddingLeft: "10px", paddingBottom: "1px" }}>
          <span className={Styles.infoDetailLeft}>ADDRESS</span>
          <span className={Styles.infoColonLeft}>:</span>
          <span className={Styles.infoDataLeft1}> {info.address}</span>
          <br />
        </div>
      </section>
    </>
  );
};
export default StudentInfo;
