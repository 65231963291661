import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "pages/Home/Home";
// Institute Dashboard
import InstitutionDashboard from "pages/InstitutionDashboard/InstitutionDashboard";
// Login
import ChooseLogin from "pages/ChooseLogin/ChooseLogin";
import AdminLogin from "pages/AdminLogin/AdminLogin";
import StudentLogin from "pages/StudentLogin/StudentLogin";
import TeacherLogin from "pages/TeacherLogin/TeacherLogin";
import ManagementLogin from "pages/ManagementLogin/ManagementLogin";
import Logout from "pages/Logout/Logout";
// Staff Dashboard
import StaffDashboard from "pages/StaffDashboard/StaffDashboard";
import StaffDashboardLanding from "pages/StaffDashboard/DashboardLanding/StaffDashboardLanding";
import StaffProfile from "pages/StaffDashboard/Profile/StaffProfile";
import StaffExams from "pages/StaffDashboard/Exams/StaffExams";
import MarksEntry from "pages/StaffDashboard/Exams/MarksEntry/MarksEntry";
import MarksEntrySheetsMid from "pages/StaffDashboard/Exams/MarksEntrySheets/MarksEntrySheetsMid";
import MarksEntrySheetsAnnual from "pages/StaffDashboard/Exams/MarksEntrySheets/MarksEntrySheetsAnnual";
import ReportCard from "pages/StaffDashboard/Exams/ReportCard/ReportCard";
import PreviewReportCard from "pages/StaffDashboard/Exams/ReportCard/PreviewReportCard";
// import MarksEntryClassTeacher from "pages/StaffDashboard/Exams/MarksEntrySheets/Category/ClassTeacher/MarksEntryClassTeacher";
// import MarksEntryDiscipline from "pages/StaffDashboard/Exams/MarksEntrySheets/Category/ClassTeacher/MarksEntryDiscipline";
// import MarksEntrySubjectTeacher from "pages/StaffDashboard/Exams/MarksEntrySheets/Category/SubjectTeacher/MarksEntrySubjectTeacher";
// import MarksEntrySubjectTeacherSem from "pages/StaffDashboard/Exams/MarksEntrySheets/Category/SubjectTeacher/MarksEntrySubjectTeacherSem";
// Admin Dashboard
import AdminDashboard from "pages/AdminDashboard/AdminDashboard";
import AdminDashboardLanding from "pages/AdminDashboard/AdminDashboardLanding/AdminDashboardLanding";
// import Admission from "pages/AdminDashboard/Admission/Admission";
// import AdminTimeTable from "pages/AdminDashboard/AdminTimeTable/AdminTimeTable";
// import EditTimetable from "pages/AdminDashboard/EditTimetable/EditTimetable";
import AddStudentProfile from "pages/AdminDashboard/AddStudentProfile/AddStudentProfile";
import AdminAttendance from "pages/AdminDashboard/AdminAttendance/AdminAttendance";
import Transport from "pages/AdminDashboard/Transport/Transport";
import AdminReportCard from "pages/AdminDashboard/ReportCard/AdminReportCard";
import AdminManagement from "pages/AdminDashboard/AdminManagement/AdminManagement";
import AdminPreviewReportCard from "pages/AdminDashboard/ReportCard/AdminPreviewReportCard";
import AdminLibrary from "pages/AdminDashboard/Library/Library";
import Students from "pages/AdminDashboard/Students/Students";
import Staff from "pages/AdminDashboard/Staff/Staff";
import AddStaffProfile from "pages/AdminDashboard/AddStaffProfile/AddStaffProfile";
import AddEvent from "pages/AdminDashboard/AddEvent/AddEvent";
import Events from "pages/AdminDashboard/Events/Events";
import AddCircular from "pages/AdminDashboard/AddCircular/AddCircular";
import AddEnrolledStudent from "pages/AdminDashboard/Library/components/AddEnrolledStudent";
// import AdminManagement from "pages/AdminDashboard/AdminManagement/AdminManagement";
import SMS from "pages/AdminDashboard/SMS/SMS";
import SingleStudentPage from "pages/AdminDashboard/SingleStudentPage/SingleStudentPage";
import StaffTimetable from "pages/StaffDashboard/StaffTimetable/StaffTimetable";
import Classroom from "pages/AdminDashboard/Classroom/Classroom";
import SingleClass from "pages/AdminDashboard/Classroom/SingleClass";
import SchoolFee from "pages/AdminDashboard/SchoolFee/SchoolFee";
import SchoolFeeCategories from "pages/AdminDashboard/SchoolFeeCategories/SchoolFeeCategories";
import SingleStudentFeePage from "pages/AdminDashboard/SingleStudentFeePage/SingleStudentFeePage";
import SingleStaffPage from "pages/AdminDashboard/SingleStaffPage/SingleStaffPage";
import SingleAdminPage from "pages/AdminDashboard/SingleAdminPage/SingleAdminPage";
import Circular from "pages/AdminDashboard/Circular/Circular";
// import AdminSingleCircular from "pages/AdminDashboard/SingleCircular/SingleCircular";
// Student Dashboard
import StudentAttendance from "pages/StudentDashboard/StudentAttendance/StudentAttendance";
import StudentTransport from "pages/StudentDashboard/Transport/StudentTransport";
import StudentReportCard from "pages/StudentDashboard/ReportCard/StudentReportCard";
import StudentPreviewReportCard from "pages/StudentDashboard/ReportCard/StudentPreviewReportCard";
// import MarksNotReleased from "pages/StudentDashboard/ReportCard/MarksNotReleased";
// import Calendar from "pages/StudentDashboard/Calendar/Calendar";
import Dashboard from "pages/StudentDashboard/Dashboard";
import DashboardLanding from "pages/StudentDashboard/DashboardLanding/DashboardLanding";
import FeePayment from "pages/StudentDashboard/FeePayment/FeePayment";
import Profile from "pages/StudentDashboard/Profile/Profile";
// import StudentCircular from "pages/StudentDashboard/Circular/Circular";
// import Event from "pages/StudentDashboard/Event/Event";
// import Events from "pages/StudentDashboard/Events/Events";
// import StudentSingleCircular from "pages/StudentDashboard/SingleCircular/SingleCircular";

// Common pages
// import ViewEvents from "pages/ViewEvents/ViewEvents";
// import ViewEvent from "pages/ViewEvent/ViewEvent";
// import ViewCircular from "pages/ViewCircular/ViewCircular";
// import ViewCirculars from "pages/ViewCirculars/ViewCirculars";
import WorkInProgress from "components/WorkInProgress/WorkInProgress";

import RequiresAuth from "components/RequiresAuth/RequiresAuth";
import Admin_Management from "pages/AdminDashboard/AdminManagement/AdminManagement";
import SingleEnrolledStudentPage from "pages/AdminDashboard/SingleEnrolledStudentPage/SingleEnrolledStudentPage";
import Canteen from "pages/AdminDashboard/Canteen/Canteen";
import Admission from "pages/AdminDashboard/Admission/Admission";
import SingleAdmission from "pages/AdminDashboard/SingleAdmissionPage/SingleAdmission";
import ManagementDashboard from "pages/ManagementDashboard/ManagementDashboard";
import ManagementDashboardLanding from "pages/ManagementDashboard/ManagementDashboardLanding/ManagementDashboardLanding";
import AddAdmission from "pages/AdminDashboard/AddAdmission/AddAdmission";
import SingleManagementPage from "pages/AdminDashboard/SingleManagementPage/SingleManagementPage";
import SingleCircular from "pages/AdminDashboard/SingleCircular/SingleCircular";
import StaffCircular from "pages/StaffDashboard/Circular/StaffCircular";
import StaffSingleCircular from "pages/StaffDashboard/SingleCircular/StaffSingleCircular";
import StudentCircular from "pages/StudentDashboard/Circular/StudentCircular";
import StudentSingleCircular from "pages/StudentDashboard/SingleCircular/StudentSingleCircular";
import Event from "pages/AdminDashboard/Event/Event";
import StaffEvents from "pages/StaffDashboard/Events/StaffEvents";
import StaffSingleEvent from "pages/StaffDashboard/StaffSingleEvent/StaffSingleEvent";
import StudentEvents from "pages/StudentDashboard/Events/StudentEvents";
import StudentSingleEvent from "pages/StudentDashboard/StudentSingleEvent/StudentSingleEvent";
import AdminTimeTable from "pages/AdminDashboard/AdminTimeTable/AdminTimeTable";
import EditTimetable from "pages/AdminDashboard/EditTimetable/EditTimetable";
import CertificateTypes from "pages/AdminDashboard/CertificatesTypes/CertificateTypes";
import CertificatePage from "pages/AdminDashboard/Certificate/CertificatePage";
import CertificateMain from "pages/AdminDashboard/CertificateMain/CertificateMain";
import Certificatepdf from "pages/AdminDashboard/CertificatePdfs/Certificatepdf";
import TeacherAttendance from "pages/StaffDashboard/Attendance/TeacherAttendance";
import TcCertificate from "pages/AdminDashboard/CertificatePdfs/TcCertificate";
import RecycleBin from "pages/AdminDashboard/RecycleBin/RecycleBin";
import StudentTimetable from "pages/StudentDashboard/StudentTimetable/StudentTimetable";
import Statistics from "pages/ManagementDashboard/Statistics/Statistics";
import Bonafide from "pages/AdminDashboard/CertificatePdfs/Bonafide";
import Backup from "pages/AdminDashboard/Backup/Backup";
import UpcomingBirthday from "components/Birthday/UpcomingBirthday";
import Permissions from "pages/AdminDashboard/Permissions/Permissions";
import SingleTeacherPermissions from "pages/AdminDashboard/Permissions/SingleTeacherPermissions";
import SingleManagementPermission from "pages/AdminDashboard/Permissions/SingleManagementPermission";
import SingleAdminPermission from "pages/AdminDashboard/Permissions/SingleAdminPermissions";
import Help from "pages/AdminDashboard/Help/Help";
import ExamReportBackup from "pages/AdminDashboard/Help/ExamReportBackup";
import GeneralBackup from "pages/AdminDashboard/Help/GeneralBackup";
import Calendar from "pages/AdminDashboard/Calendar/Calendar";
import StaffHelp from "pages/StaffDashboard/Help/StaffHelp";
import ExamsHelp from "pages/StaffDashboard/Help/ExamsHelp";
import ManagementCalendar from "pages/ManagementDashboard/Calendar/ManagementCalendar";
import StudentCalendar from "pages/StudentDashboard/Calendar/StudentCalendar";
import StaffCalendar from "pages/StaffDashboard/Calendar/StaffCalendar";
import ManagementAddEvent from "pages/ManagementDashboard/AddEvent/ManagementAddEvent";
import ManagementEvent from "pages/ManagementDashboard/Event/ManagementEvent";
import ManagementEvents from "pages/ManagementDashboard/Events/ManagementEvents";
import ManagementCircular from "pages/ManagementDashboard/Circular/ManagementCircular";
import ManagementAddCircular from "pages/ManagementDashboard/AddCircular/ManagementAddCircular";
import ManagementSingleCircular from "pages/ManagementDashboard/SingleCircular/ManagementSingleCircular";
import ManagementTimeTable from "pages/ManagementDashboard/ManagementTimeTable/ManagementTimeTable";
import ManagementEditTimetable from "pages/ManagementDashboard/EditTimetable/ManagementEditTimetable";
import ManagementStaff from "pages/ManagementDashboard/Staff/ManagementStaff";
import ManagementAddStaffProfile from "pages/ManagementDashboard/AddStaffProfile/ManagementAddStaffProfile";
import ManagementSingleStaffPage from "pages/ManagementDashboard/SingleStaffPage/ManagementSingleStaffPage";
import ManagementStudents from "pages/ManagementDashboard/Students/ManagementStudents";
// import ManagementAddStudentProfile from "pages/ManagementDashboard/AddStudentProfile/ManagementAddStudentProfile";
import ManagementAddStudentProfiles from "pages/ManagementDashboard/AddStudentProfile/ManagementAddStudentProfile";
import ManagementSingleStudentPage from "pages/ManagementDashboard/SingleStudentPage/ManagementSingleStudentPage";
import ManagementClassroom from "pages/ManagementDashboard/Classroom/ManagementClassroom";
import ManagementSingleClass from "pages/ManagementDashboard/Classroom/ManagementSingleClass";
import ManagementBackup from "pages/ManagementDashboard/Backup/ManagementBackup";
import ManagementReportCard from "pages/ManagementDashboard/ReportCard/ManagementReportCard";
import ManagementPreviewReportCard from "pages/ManagementDashboard/ReportCard/ManagementPreviewReportCard";
import ManagementAddStudentProfile from "pages/ManagementDashboard/AddAdmission/AddAdmission";
import ManagementSingleStudentAddmissionPage from "pages/ManagementDashboard/SingleAdmissionPage/SingleAdmission";
import ManagementSchoolFeeCategories from "pages/ManagementDashboard/SchoolFeeCategories/SchoolFeeCategories";
import ManagementSchoolFee from "pages/ManagementDashboard/SchoolFee/SchoolFee";
import ManagementSingleStudentFeePage from "pages/ManagementDashboard/SingleStudentFeePage/SingleStudentFeePage";
import ManagementTransport from "pages/ManagementDashboard/Transport/Transport";
import ManagementSingleEnrolledStudentPage from "pages/ManagementDashboard/SingleEnrolledStudentPage/SingleEnrolledStudentPage";
import ManagementCanteen from "pages/ManagementDashboard/Canteen/Canteen";
import ManagementRecycleBin from "pages/ManagementDashboard/ManagementRecycleBin/ManagementRecycleBin";
import NewAdminAttendence from "pages/AdminDashboard/AdminAttendance/NewAdminAttendence";
import BusDetail from "pages/AdminDashboard/BusDetails/BusDetail";
import BusDetails from "pages/AdminDashboard/BusDetails/BusDetail";
import TranspostEnrolledStudents from "pages/AdminDashboard/Transport/Transport";
import ManualTcPage from "pages/AdminDashboard/Certificate/ManualTcPage";
// import downloadManualTc from "pages/AdminDashboard/Certificate/DownloadManaual";
import DownloadManualTc from "pages/AdminDashboard/Certificate/DownloadManaual";
import LibrartCheckout from "pages/AdminDashboard/Library/LibraryCheckout";
import ManagementAdmission from "pages/ManagementDashboard/Admission/Admission";
import ConductCertifiacte from "pages/AdminDashboard/Certificate/ConductCertifiacte";
import ManualConductDownload from "pages/AdminDashboard/Certificate/ManualConductDownload";
import AutoConductCertificate from "pages/AdminDashboard/CertificatePdfs/AutoConductCertificate";
import AutoConductDownload from "pages/AdminDashboard/CertificatePdfs/AutoConductDownload";
import ManagementAttendance from "pages/ManagementDashboard/Attendance/ManagementAttendance";
import NewTeacherAttendance from "pages/StaffDashboard/Attendance/NewTeacherAttendance";
import ManagementSMS from "pages/ManagementDashboard/SMS/SMS";
import AcademicCalendarHelp from "pages/AdminDashboard/Help/AcademicCalendarHelp";
import StatisticsHelp from "pages/AdminDashboard/Help/StatisticsHelp";
import ManagementCertificateTypes from "pages/ManagementDashboard/CertificatesTypes/CertificateTypes";
import ManagementCertificateMain from "pages/ManagementDashboard/CertificateMain/CertificateMain";
import ManagementCertificatePage from "pages/ManagementDashboard/Certificate/CertificatePage";
import ManagementConductCertifiacte from "pages/ManagementDashboard/Certificate/ConductCertifiacte";
import ManagementDownloadManualTc from "pages/ManagementDashboard/Certificate/DownloadManaual";
import ManagementManualTcPage from "pages/ManagementDashboard/Certificate/ManualTcPage";
import ManagementCertificatepdf from "pages/ManagementDashboard/CertificatePdfs/Certificatepdf";
import ManagementTcCertificate from "pages/ManagementDashboard/CertificatePdfs/TcCertificate";
import ManagementBonafide from "pages/ManagementDashboard/CertificatePdfs/Bonafide";
import ManagementAutoConductCertificate from "pages/ManagementDashboard/CertificatePdfs/AutoConductCertificate";
import ManagementAutoConductDownload from "pages/ManagementDashboard/CertificatePdfs/AutoConductDownload";
import ManualStudy from "pages/AdminDashboard/Certificate/ManualStudy";
import ManualStudyDownload from "pages/AdminDashboard/Certificate/DownloadManualStudy";
import ManualBonafide from "pages/AdminDashboard/Certificate/BonafideManual";
import DownloadBonafide from "pages/AdminDashboard/Certificate/DownloadBonafide";
import Settings from "pages/AdminDashboard/Settings/Settings";
import ManagementSettings from "pages/ManagementDashboard/ManagementSetting/ManagementSettings";
import About from "pages/AdminDashboard/About/About";
import StudentClassroom from "pages/StudentDashboard/Classroom/StudentClassroom";
import StaffClassroom from "pages/StaffDashboard/Classroom/StaffClassroom";
import StudentTeacher from "pages/StaffDashboard/Student/StudentTeacher";
import SingleStaffClass from "pages/StaffDashboard/Classroom/SingleStaffClass";
import StudentCanteen from "pages/StudentDashboard/Canteen/StudentCanteen";
import Notification from "pages/AdminDashboard/Notification/AddNotification";
import StudentNotification from "pages/StudentDashboard/Notification/StudentNotification";
import StaffNotification from "pages/StaffDashboard/Notification/StaffNotification";
import ManagementNotification from "pages/ManagementDashboard/Notification/ManagementNotification";
import Branding from "pages/AdminDashboard/Branding/Branding";
import SingleStudentTeacher from "pages/StaffDashboard/SingleStudent/Single_Student_Teacher";
import TeacherSettings from "pages/StaffDashboard/StaffSettings/StaffSettings";
import StatisticsReport from "pages/AdminDashboard/AdminAttendance/StatisticsReport";
import AddManagementProfile from "pages/AdminDashboard/AddManagementProfile/AddManagementProfile";
import Library from "pages/StudentDashboard/Library/Library";
import StudentAbout from "pages/StudentDashboard/About/StudentAbout";
import SendNotification from "pages/AdminDashboard/Notification/SendNotification";
import ForgetPassword from "pages/ForgetPassword/ForgetPassword";
import SingleHelp from "pages/AdminDashboard/Help/SingleHelp";
import SingleModuleHelp from "pages/AdminDashboard/Help/SingleModuleHelp";
import NewHelp from "pages/AdminDashboard/Help/NewHelp";
import StudentSettings from "pages/StudentDashboard/StudentSettings/StudentSettings";
import AddBook from "pages/AdminDashboard/Library/components/AddBooks";
import ManagementAbout from "pages/ManagementDashboard/About/ManagementAbout";
import TeacherAbout from "pages/StaffDashboard/About/TeacherAbout";
import TeacherStatisticsReport from "pages/StaffDashboard/Attendance/TeacherStatisticsReport";
import SingleTeacherHelp from "pages/StaffDashboard/Help/SingleTeacherHelp";
import StudentHelp from "pages/StudentDashboard/Help/StudentHelp";
import SingleStudentHelp from "pages/StudentDashboard/Help/SingleStudentHelp";
import ManagementHelp from "pages/ManagementDashboard/Help/ManagementHelp";
import SingleManagementHelp from "pages/ManagementDashboard/Help/SingleManagementHelp";
import ReportCentre from "pages/AdminDashboard/ReportCentre/ReportCentre";
import ManagementReportCentre from "pages/ManagementDashboard/ReportCentre/ManagementReportCentre";
import ManagementStatisticsReport from "pages/ManagementDashboard/Attendance/ManagementStatisticsReport";
import StatisticsRecentActivity from "pages/ManagementDashboard/Statistics/StatisticsRecentActivity";
import StatisticsNoticeBoard from "pages/ManagementDashboard/Statistics/StatisticsNoticeBoard";
import DashboardNotification from "pages/AdminDashboard/DashboardNotification/DashboardNotification";
import ViewNotification from "pages/AdminDashboard/Notification/ViewNotification";
import StudentProfile from "pages/StudentDashboard/Profile/StudentProfile";
import NewBackup from "pages/AdminDashboard/Backup/NewBackup";
const RouterConfig = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="chooseLogin" element={<ChooseLogin />} />
        <Route path="adminLogin" element={<AdminLogin />} />
        <Route path="teacherLogin" element={<TeacherLogin />} />
        <Route path="institution" element={<InstitutionDashboard />} />
        <Route path="studentLogin" element={<StudentLogin />} />
        <Route path="managementLogin" element={<ManagementLogin />} />
        <Route path="forgotPassword" element={<ForgetPassword />} />
        <Route
          path="student/dashboard"
          element={
            <RequiresAuth>
              <Dashboard />
            </RequiresAuth>
          }
        >
          <Route index element={<DashboardLanding />} />
          {/*  profile */}
          {/* <Route path="profile" element={<StudentProfile />} /> */}
          <Route path="profile" element={<Profile />} />
          {/* events  */}
          <Route path="events" element={<StudentEvents />} />
          <Route path="events/:id" element={<StudentSingleEvent />} />
          {/* circular  */}
          <Route path="circular" element={<StudentCircular />} />
          <Route path="circular/:id" element={<StudentSingleCircular />} />
          {/*classroom*/}
          <Route path="classroom" element={<StudentClassroom />} />
          {/*assignments*/}
          <Route path="assignments" element={<WorkInProgress />} />
          {/* calendar */}
          <Route path="calendar" element={<StudentCalendar />} />
          {/* library */}
          <Route path="library" element={<WorkInProgress />} />
          <Route path="library" element={<Library />} />
          {/*transport */}
          <Route path="transport" element={<StudentTransport />} />
          {/*canteen */}
          <Route path="canteen" element={<StudentCanteen />} />
          {/* report */}
          <Route path="report" element={<StudentReportCard />} />
          <Route path="report/:id" element={<StudentPreviewReportCard />} />
          {/* <Route path="report/null" element={<MarksNotReleased />} /> */}
          {/* attendance  */}
          <Route path="attendance" element={<StudentAttendance />} />
          {/* fees */}
          <Route path="fee" element={<FeePayment />} />
          {/* timetable */}
          <Route path="timetable" element={<StudentTimetable />} />
          {/* notification */}
          <Route path="notification" element={<WorkInProgress />} />
          {/* <Route path="notification" element={<StudentNotification />} /> */}
          {/* help */}
          <Route path="help" element={<StudentHelp />} />
          <Route path="help/:id" element={<SingleStudentHelp />} />
          {/* logout */}
          <Route path="logout" element={<Logout allRequest={false} />} />
          <Route path="logoutall" element={<Logout allRequest={true} />} />
          {/* others */}
          <Route path="settings" element={<StudentSettings />} />
          <Route path="about" element={<StudentAbout />} />
          <Route path="knowledgeCentre" element={<WorkInProgress />} />
        </Route>

        <Route
          path="staff/dashboard"
          element={
            <RequiresAuth>
              <StaffDashboard />
            </RequiresAuth>
          }
        >
          <Route index element={<StaffDashboardLanding />} />
          {/* birthday */}
          <Route path="birthday" element={<UpcomingBirthday />} />
          {/*  profile */}
          <Route path="profile" element={<StaffProfile />} />
          {/*  students */}
          <Route path="students" element={<StudentTeacher />} />
          <Route path="students/:id" element={<SingleStudentTeacher />} />
          {/*  events */}
          <Route path="events" element={<StaffEvents />} />
          <Route path="events/:id" element={<StaffSingleEvent />} />
          {/*  circular */}
          <Route path="circular" element={<StaffCircular />} />
          <Route path="circular/:id" element={<StaffSingleCircular />} />
          {/*classroom*/}
          <Route path="classroom" element={<StaffClassroom />} />
          <Route path="classroom/:id" element={<SingleStaffClass />} />
          {/*assignments*/}
          <Route path="assignments" element={<WorkInProgress />} />
          {/*knowledgeCentre*/}
          <Route path="knowledgeCentre" element={<WorkInProgress />} />
          {/*calendar*/}
          <Route path="calendar" element={<StaffCalendar />} />
          {/*exams*/}
          <Route path="exams" element={<StaffExams />} />
          <Route path="exams/marksentry" element={<MarksEntry />} />
          <Route
            path="exams/marksentry/mid"
            element={<MarksEntrySheetsMid />}
          />
          <Route
            path="exams/marksentry/annual"
            element={<MarksEntrySheetsAnnual />}
          />
          <Route path="exams/report" element={<ReportCard />} />
          <Route
            path="exams/report/preview/:term/:grade"
            element={<PreviewReportCard />}
          />
          {/* attendance  */}
          <Route path="attendance" element={<NewTeacherAttendance />} />
          <Route path="attendance/:id" element={<TeacherStatisticsReport />} />
          {/* timetable */}
          <Route path="timetable" element={<StaffTimetable />} />
          {/* help */}
          <Route path="help" element={<StaffHelp />} />
          <Route path="help/:id" element={<SingleTeacherHelp />} />
          <Route path="help/examHelp" element={<ExamsHelp />} />
          {/* logout */}
          <Route path="logout" element={<Logout allRequest={false} />} />
          <Route path="logoutall" element={<Logout allRequest={true} />} />
          {/* others */}
          <Route path="notification" element={<StaffNotification />} />
          <Route path="settings" element={<TeacherSettings />} />
          <Route path="about" element={<TeacherAbout />} />
        </Route>

        <Route
          path="admin/dashboard"
          element={
            <RequiresAuth>
              <AdminDashboard />
            </RequiresAuth>
          }
        >
          <Route index element={<AdminDashboardLanding />} />
          {/* birthday */}
          <Route path="birthday" element={<UpcomingBirthday />} />
          {/*  students */}
          <Route path="students" element={<Students />} />
          <Route path="students/add" element={<AddStudentProfile />} />
          <Route path="students/:id" element={<SingleStudentPage />} />
          {/* staff  */}
          <Route path="staff" element={<Staff />} />
          <Route path="staff/add" element={<AddStaffProfile />} />
          <Route path="staff/:id" element={<SingleStaffPage />} />
          {/* admission */}
          <Route path="admission" element={<Admission />} />
          <Route path="admission/add" element={<AddAdmission />} />
          <Route path="admission/:id" element={<SingleAdmission />} />
          {/* events */}
          <Route path="events" element={<Events />} />
          <Route path="events/add" element={<AddEvent />} />
          <Route path="events/:id" element={<Event />} />
          {/* circular */}
          <Route path="circular" element={<Circular />} />
          <Route path="circular/add" element={<AddCircular />} />
          <Route path="circular/:id" element={<SingleCircular />} />
          {/* classroom */}
          <Route path="classroom" element={<Classroom />} />
          <Route path="classroom/:id" element={<SingleClass />} />
          <Route path="classroom/:id/:id" element={<SingleStaffPage />} />
          {/* reportCentre */}
          <Route path="reportCentre" element={<ReportCentre />} />
          {/* calendar */}
          <Route path="calendar" element={<Calendar />} />
          {/* library */}
          <Route path="library" element={<AdminLibrary />} />
          <Route path="library/add" element={<AddBook />} />
          <Route path="libraryRecord" element={<LibrartCheckout />} />
          <Route
            path="library/addenrolledstudent"
            element={<AddEnrolledStudent />}
          />
          {/* transport */}
          <Route path="transport" element={<TranspostEnrolledStudents />} />
          <Route
            path="transport/enroll/one/:id"
            element={<SingleEnrolledStudentPage />}
          />
          <Route path="busDetails" element={<BusDetails />} />
          {/* canteen */}
          <Route path="canteen" element={<Canteen />} />
          {/* permissions */}
          <Route path="permissions" element={<Permissions />} />
          <Route
            path="permissions/admin/:id"
            element={<SingleAdminPermission />}
          />
          <Route
            path="permissions/teacher/:id"
            element={<SingleTeacherPermissions />}
          />
          <Route
            path="permissions/management/:id"
            element={<SingleManagementPermission />}
          />
          {/* report */}
          <Route path="report" element={<AdminReportCard />} />
          <Route
            path="report/preview/:term/:grade"
            element={<AdminPreviewReportCard />}
          />
          {/* certificate */}
          <Route path="certificate" element={<CertificateTypes />} />
          <Route path="certificate/:id" element={<CertificatePage />} />
          <Route path="certificate/:id/:id" element={<CertificateMain />} />
          <Route
            path="certificate/bonafide/bonafide/:id"
            element={<Bonafide />}
          />
          <Route
            path="certificate/:id/:id/certi/:id"
            element={<Certificatepdf />}
          />
          <Route
            path="certificate/:id/certi/:id"
            element={<Certificatepdf />}
          />
          <Route
            path="certificate/:id/:id/certi/tc"
            element={<TcCertificate />}
          />
          <Route
            path="certificate/transferc/manual"
            element={<ManualTcPage />}
          />
          <Route
            path="certificate/transferc/manual/download"
            element={<DownloadManualTc />}
          />
          <Route
            path="certificate/conduct/:id"
            element={<AutoConductCertificate />}
          />
          <Route
            path="certificate/conduct/:id/download"
            element={<AutoConductDownload />}
          />
          <Route
            path="certificate/conduct/manual"
            element={<ConductCertifiacte />}
          />
          <Route
            path="certificate/conduct/manual/download"
            element={<ManualConductDownload />}
          />
          <Route path="certificate/studyc/manual" element={<ManualStudy />} />
          <Route
            path="certificate/studyc/manual/downloadstudy"
            element={<ManualStudyDownload />}
          />
          <Route
            path="certificate/bonafide/manual"
            element={<ManualBonafide />}
          />
          <Route
            path="certificate/bonafide/manual/download"
            element={<DownloadBonafide />}
          />
          {/* attendance */}
          <Route path="attendance" element={<NewAdminAttendence />} />
          <Route path="attendance/:id" element={<StatisticsReport />} />
          {/* fees */}
          <Route path="fees" element={<SchoolFeeCategories />} />
          <Route path="fees/:id" element={<SchoolFee />} />
          <Route path="fees/:id/:id" element={<SingleStudentFeePage />} />
          <Route path="fee" element={<FeePayment />} />
          {/* timetable */}
          <Route path="timetable" element={<AdminTimeTable />} />
          <Route path="timetable/:id" element={<EditTimetable />} />
          {/* sms */}
          <Route path="sms" element={<SMS />} />
          {/* notification */}
          <Route path="notification" element={<Notification />} />
          <Route path="notification/view" element={<SendNotification />} />
          <Route path="notification/view/:id" element={<ViewNotification />} />
          {/* recylcebin */}
          <Route path="recylcebin" element={<RecycleBin />} />
          {/* admin-management */}
          <Route path="admin-management" element={<AdminManagement />} />
          <Route path="admin-management/:id" element={<SingleAdminPage />} />
          <Route
            path="admin-management/add"
            element={<AddManagementProfile />}
          />
          <Route
            path="admin-management/management/:id"
            element={<SingleManagementPage />}
          />
          {/* backup */}
          <Route path="backup" element={<NewBackup />} />
          {/* <Route path="backup" element={<Backup />} /> */}
          {/* branding */}
          <Route path="branding" element={<Branding />} />
          {/* backup */}
          <Route path="tools" element={<WorkInProgress />} />
          {/* <Route path="tools" element={<Tools />} /> */}
          {/* help */}
          {/* <Route path="help/:id" element={<SingleHelp />} /> */}
          {/* <Route path="help/:id" element={<SingleModuleHelp />} /> */}
          {/* <Route path="help/generalBackup" element={<GeneralBackup />} />
          <Route
            path="help/AcademicCalendarhelp"
            element={<AcademicCalendarHelp />}
          />
          <Route path="help/Statisticshelp" element={<StatisticsHelp />} /> */}
          <Route path="help" element={<Help />} />
          <Route path="help/:id" element={<NewHelp />} />
          <Route path="help/examReportBackup" element={<ExamReportBackup />} />
          {/* logout */}
          <Route path="logout" element={<Logout allRequest={false} />} />
          <Route path="logoutall" element={<Logout allRequest={true} />} />
          {/* others */}
          <Route path="settings" element={<Settings />} />
          <Route path="about" element={<About />} />
          <Route
            path="dashboardnotification"
            element={<DashboardNotification />}
          />
        </Route>

        <Route
          path="management/dashboard"
          element={
            <RequiresAuth>
              <ManagementDashboard />
            </RequiresAuth>
          }
        >
          <Route index element={<ManagementDashboardLanding />} />
          {/* birthday */}
          <Route path="birthday" element={<UpcomingBirthday />} />
          {/*  profile */}
          <Route path="profile" element={<WorkInProgress />} />
          {/*  statistics */}
          <Route path="statistics" element={<Statistics />} />
          <Route path="statistics/:id" element={<StatisticsNoticeBoard />} />
          <Route
            path="statistics/event/:id"
            element={<StatisticsRecentActivity />}
          />
          {/*  students */}
          <Route path="students" element={<ManagementStudents />} />
          <Route
            path="students/add"
            element={<ManagementAddStudentProfiles />}
          />
          <Route
            path="students/:id"
            element={<ManagementSingleStudentPage />}
          />
          {/* staff  */}
          <Route path="staff" element={<ManagementStaff />} />
          <Route path="staff/add" element={<ManagementAddStaffProfile />} />
          <Route path="staff/:id" element={<ManagementSingleStaffPage />} />
          {/* admission  */}
          <Route path="admission" element={<ManagementAdmission />} />
          <Route
            path="admission/add"
            element={<ManagementAddStudentProfile />}
          />
          <Route
            path="admission/:id"
            element={<ManagementSingleStudentAddmissionPage />}
          />
          {/* events  */}
          <Route path="events" element={<ManagementEvents />} />
          <Route path="events/add" element={<ManagementAddEvent />} />
          <Route path="events/:id" element={<ManagementEvent />} />
          {/* circular  */}
          <Route path="circular" element={<ManagementCircular />} />
          <Route path="circular/add" element={<ManagementAddCircular />} />
          <Route path="circular/:id" element={<ManagementSingleCircular />} />
          {/*classroom*/}
          <Route path="classroom" element={<ManagementClassroom />} />
          <Route path="classroom/:id" element={<ManagementSingleClass />} />
          <Route
            path="classroom/:id/:id"
            element={<ManagementSingleStaffPage />}
          />
          {/* reportCentre */}
          <Route path="reportCentre" element={<ManagementReportCentre />} />
          {/* calendar */}
          <Route path="calendar" element={<ManagementCalendar />} />
          {/*transport */}
          <Route path="transport" element={<ManagementTransport />} />
          <Route
            path="transport/enroll/one/:id"
            element={<ManagementSingleEnrolledStudentPage />}
          />
          {/*canteen */}
          <Route path="canteen" element={<ManagementCanteen />} />
          {/* report */}
          <Route path="report" element={<ManagementReportCard />} />
          <Route
            path="report/preview/:term/:grade"
            element={<ManagementPreviewReportCard />}
          />
          {/* certificate  */}
          <Route path="certificate" element={<ManagementCertificateTypes />} />
          <Route
            path="certificate/:id"
            element={<ManagementCertificatePage />}
          />
          <Route
            path="certificate/:id/:id"
            element={<ManagementCertificateMain />}
          />
          <Route
            path="certificate/:id/:id/certi/:id"
            element={<ManagementCertificatepdf />}
          />
          <Route
            path="certificate/:id/certi/:id"
            element={<ManagementCertificatepdf />}
          />
          <Route
            path="certificate/:id/:id/certi/tc"
            element={<ManagementTcCertificate />}
          />
          <Route
            path="certificate/bonafide/bonafide/:id"
            element={<ManagementBonafide />}
          />
          <Route
            path="certificate/transferc/manualTc"
            element={<ManagementManualTcPage />}
          />
          <Route
            path="certificate/transferc/manualTc/download"
            element={<ManagementDownloadManualTc />}
          />
          <Route
            path="certificate/conduct/:id"
            element={<ManagementAutoConductCertificate />}
          />
          <Route
            path="certificate/conduct/:id/download"
            element={<ManagementAutoConductDownload />}
          />
          <Route
            path="certificate/conduct/manualconductcerti"
            element={<ManagementConductCertifiacte />}
          />
          <Route
            path="certificate/conduct/manualconductcerti/conductdownload"
            element={<ManualConductDownload />}
          />
          <Route
            path="certificate/studyc/manualstudy"
            element={<ManagementAutoConductCertificate />}
          />
          {/* attendance  */}
          <Route path="attendance" element={<ManagementAttendance />} />
          <Route
            path="attendance/:id"
            element={<ManagementStatisticsReport />}
          />
          {/* fees */}
          <Route path="fees" element={<ManagementSchoolFeeCategories />} />
          <Route path="fees/:id" element={<ManagementSchoolFee />} />
          <Route
            path="fees/:id/:id"
            element={<ManagementSingleStudentFeePage />}
          />
          {/* timetable */}
          <Route path="timetable" element={<ManagementTimeTable />} />
          <Route path="timetable/:id" element={<ManagementEditTimetable />} />
          {/* sms */}
          <Route path="sms" element={<ManagementSMS />} />
          {/* notification */}
          <Route path="notification" element={<WorkInProgress />} />
          {/* <Route path="notification" element={<ManagementNotification />} /> */}
          {/* recylcebin */}
          <Route path="recylcebin" element={<ManagementRecycleBin />} />
          {/* backup */}
          <Route path="backup" element={<ManagementBackup />} />
          {/* help */}
          <Route path="help" element={<ManagementHelp />} />
          <Route path="help/:id" element={<SingleManagementHelp />} />
          {/* logout */}
          <Route path="logout" element={<Logout allRequest={false} />} />
          <Route path="logoutall" element={<Logout allRequest={true} />} />
          {/* others */}
          <Route path="settings" element={<ManagementSettings />} />
          <Route path="about" element={<ManagementAbout />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default RouterConfig;
