import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Styles from "../Midterm.module.scss";
import Watermark from "../Watermark/Watermark";

const ScholasticRecord = ({
  gradeCategory,
  type,
  marksList,
  subcodeList,
  completeData,
}) => {
  const params = useParams();
  console.log("cd is", completeData);
  // console.log("subcodeList is", subcodeList);
  console.log("marksList is", marksList);
  // console.log("gradeCategory is", gradeCategory);
  const [term, setTerm] = useState("");
  const [reportClass, setReportClass] = useState("");
  const [termtodisplay, setTermtoDisplay] = useState("");
  useEffect(() => {
    setTerm(params.term);
    setReportClass(params.grade);

    if (term == "anual") {
      if (reportClass == "10" || reportClass == "12") {
        setTermtoDisplay("PRE-BOARD EXAMINATION");
      } else {
        setTermtoDisplay("ANNUAL EXAMINATION");
      }
    } else {
      setTermtoDisplay("MIDTERM EXAMINATION");
    }
  }, [term, reportClass]);

  function GetGrade1to5(TotalMarks) {
    let total;
    total = parseInt(TotalMarks) >= 0 ? parseFloat(TotalMarks) : "-";
    let grade;
    switch (true) {
      case total >= 45.5 && total <= 50:
        grade = "A1";
        break;
      case total >= 40.5 && total <= 45:
        grade = "A2";
        break;
      case total >= 35.5 && total <= 40:
        grade = "B1";
        break;
      case total >= 30.5 && total <= 35:
        grade = "B2";
        break;
      case total >= 25.5 && total <= 30:
        grade = "C1";
        break;
      case total >= 20.5 && total <= 25:
        grade = "C2";
        break;
      case total >= 15.5 && total <= 20:
        grade = "D";
        break;
      case total <= 15:
        grade = "E";
        break;
      default:
        grade = "N/A";
    }
    return grade;
  }

  function GetGrade(TotalMarks) {
    let total;
    total = parseInt(TotalMarks) >= 0 ? parseFloat(TotalMarks) : "-";
    let grade;
    switch (true) {
      case total >= 91 && total <= 100:
        grade = "A1";
        break;
      case total >= 81 && total <= 90:
        grade = "A2";
        break;
      case total >= 71 && total <= 80:
        grade = "B1";
        break;
      case total >= 61 && total <= 70:
        grade = "B2";
        break;
      case total >= 51 && total <= 60:
        grade = "C1";
        break;
      case total >= 41 && total <= 50:
        grade = "C2";
        break;
      case total >= 31 && total <= 40:
        grade = "D";
        break;
      case total <= 30:
        grade = "E";
        break;
      default:
        grade = "N/A";
    }
    return grade;
  }
  function GetMark(subName) {
    const found = completeData.find((el) => el.subject_name == subName);
    if (found) {
      for (let idx = 0; idx < completeData.length; idx++) {
        if (completeData[idx].subject_name == subName) {
          return completeData[idx];
        }
      }
    }
    return {};
  }
  function GetMarks(subName) {
    const found = completeData.find((el) => el.elective_name == subName);
    if (found) {
      for (let idx = 0; idx < completeData.length; idx++) {
        if (completeData[idx].elective_name == subName) {
          return completeData[idx];
        }
      }
    }
    return {};
  }

  console.log(term, reportClass);

  const calculateInternalMarks = (data, mainSub) => {
    let mainSubMarks = data.filter((item) =>
      mainSub.includes(item.subject_name)
    );
    let sum = 0;
    mainSubMarks
      .filter((item) => item.internal !== "-")
      .map((item) => (sum = sum + parseInt(item.internal)));
    return sum;
  };

  const calculateExternalMarks = (data, mainSub) => {
    let mainSubMarks = data.filter((item) =>
      mainSub.includes(item.subject_name)
    );
    let sum = 0;
    mainSubMarks
      .filter((item) => item.external !== "-")
      .map((item) => (sum = sum + parseInt(item.external)));
    console.log(sum);
    return sum;
  };

  const calculateSum = (mainSub) => {
    let mainSubMarks = marksList.filter((item) =>
      mainSub.includes(Object.keys(item)[0])
    );
    const sum = mainSubMarks
      .filter((item) => Object.values(item)[0] !== "-")
      .reduce((accumulator, mark) => {
        return accumulator + parseInt(Object.values(mark)[0]);
      }, 0);
    return sum;
  };
  const handleTotalNew = (part) => {
    let mainSubjects = [
      "ENGLISH LAN & LIT.",
      "HINDI COURSE-B",
      "KANNADA",
      "MATHEMATICS",
      "SCIENCE",
      "SOCIAL SCIENCE",
      "INFORMATION TECHNOLOGY",
    ];
    let returnValue = 0;
    for (let idx = 0; idx < mainSubjects.length; idx++) {
      const found = completeData.find(
        (el) => el.subject_name == mainSubjects[idx]
      );
      if (found) {
        for (let idx2 = 0; idx2 < completeData.length; idx2++) {
          if (completeData[idx2].subject_name == mainSubjects[idx]) {
            if (completeData[idx2][part] != "-")
              returnValue =
                parseInt(returnValue) + parseInt(completeData[idx2][part]);
          }
        }
      }
    }
    console.log("total is", returnValue);
    let total;
    total = parseInt(returnValue) >= 0 ? parseFloat(returnValue) : "-";
    console.log("Total ", total);
    return total;
  };
  const handleTotalNewfor11 = (part) => {
    let mainSubjects = [
      "ENGLISH CORE",
      "ECONOMICS",
      "BUSINESS STUDIES",
      "ACCOUNTANCY",
      "COMPUTER SCIENCE",
      "MATHEMATICS",
    ];
    let returnValue = 0;
    for (let idx = 0; idx < mainSubjects.length; idx++) {
      const found = completeData.find(
        (el) => el.subject_name == mainSubjects[idx]
      );
      if (found) {
        for (let idx2 = 0; idx2 < completeData.length; idx2++) {
          if (completeData[idx2].subject_name == mainSubjects[idx]) {
            if (completeData[idx2][part] != "-")
              returnValue = returnValue + parseInt(completeData[idx2][part]);
          }
        }
      }
    }
    console.log("total is", returnValue);
    let total;
    total = parseInt(returnValue) >= 0 ? parseFloat(returnValue) : "-";
    return total;
  };

  const handleInternalTotal = () => {
    if (gradeCategory === "Grade1to5") {
      let mainSubjects = [
        "ENGLISH",
        "HINDI",
        "KANNADA",
        "MATHEMATICS",
        "ENVIRONMENTAL STUDIES",
      ];
      let sum = calculateInternalMarks(completeData, mainSubjects);
      return sum ? sum : "-";
    } else if (
      gradeCategory === "Grade6" ||
      gradeCategory === "Grade7" ||
      gradeCategory === "Grade8"
    ) {
      let mainSubjects = [
        "ENGLISH",
        "HINDI",
        "KANNADA",
        "MATHEMATICS",
        "SCIENCE",
        "SOCIAL SCIENCE",
      ];
      let sum = calculateInternalMarks(completeData, mainSubjects);
      return sum ? sum : "-";
    }
  };

  const handleExternalTotal = () => {
    if (gradeCategory === "Grade1to5") {
      let mainSubjects = [
        "ENGLISH",
        "HINDI",
        "KANNADA",
        "MATHEMATICS",
        "ENVIRONMENTAL STUDIES",
      ];
      let sum = calculateExternalMarks(completeData, mainSubjects);
      return sum ? sum : "-";
    } else if (
      gradeCategory === "Grade6" ||
      gradeCategory === "Grade7" ||
      gradeCategory === "Grade8"
    ) {
      let mainSubjects = [
        "ENGLISH",
        "HINDI",
        "KANNADA",
        "MATHEMATICS",
        "SCIENCE",
        "SOCIAL SCIENCE",
      ];
      let sum = calculateExternalMarks(completeData, mainSubjects);
      return sum ? sum : "-";
    }
  };

  const handleTotal = (n) => {
    if (gradeCategory === "Grade1to5") {
      let mainSubjects = [
        "ENGLISH",
        "HINDI",
        "KANNADA",
        "MATHEMATICS",
        "ENVIRONMENTAL STUDIES",
      ];
      let sum = calculateSum(mainSubjects);
      return sum ? sum : "-";
    } else if (
      gradeCategory === "Grade6" ||
      gradeCategory === "Grade7" ||
      gradeCategory === "Grade8"
    ) {
      let mainSubjects = [
        "ENGLISH",
        "HINDI",
        "KANNADA",
        "MATHEMATICS",
        "SCIENCE",
        "SOCIAL SCIENCE",
      ];
      let sum = calculateSum(mainSubjects);
      return sum ? sum : "-";
    } else if (gradeCategory === "Grade9" || gradeCategory === "Grade10") {
      let mainSubjects = [
        "ENGLISH LAN & LIT.",
        "HINDI COURSE-B",
        "KANNADA",
        "MATHEMATICS",
        "SCIENCE",
        "SOCIAL SCIENCE",
        "INFORMATION TECHNOLOGY",
      ];
      let sum = calculateSum(mainSubjects);
      return sum ? sum : "-";
    } else if (
      (gradeCategory.includes("Grade11") ||
        gradeCategory.includes("Grade12")) &&
      gradeCategory.includes("Commerce")
    ) {
      let mainSubjects = [
        "ENGLISH CORE",
        "ECONOMICS",
        "BUSINESS STUDIES",
        "ACCOUNTANCY",
        "COMPUTER SCIENCE",
        "MATHEMATICS",
      ];
      let mainSubMarks = marksList.filter((item) =>
        mainSubjects.includes(Object.keys(item)[0])
      );
      console.log("mainSubMarks", mainSubMarks);
      const sum = parseInt(
        mainSubMarks
          .filter((item) => Object.values(item)[n] !== "-")
          .reduce((accumulator, mark) => {
            return accumulator + parseInt(Object.values(mark)[0][n]);
          }, 0)
      );
      console.log("gradeCategory", sum);
      return sum ? sum : "-";
    } else if (
      (gradeCategory.includes("Grade11") ||
        gradeCategory.includes("Grade12")) &&
      gradeCategory.includes("Science")
    ) {
      let mainSubjects = [
        "ENGLISH CORE",
        "MATHEMATICS",
        "PHYSICS",
        "CHEMISTRY",
        "COMPUTER SCIENCE",
        "BIOLOGY",
      ];
      let mainSubMarks = marksList.filter((item) =>
        mainSubjects.includes(Object.keys(item)[0])
      );
      console.log("mainSubMarks", mainSubMarks);
      const sum = mainSubMarks
        .filter((item) => Object.values(item)[0][n] !== "-")
        .reduce((accumulator, mark) => {
          return accumulator + parseInt(Object.values(mark)[0][n]);
        }, 0);
      // console.log(gradeCategory, sum);
      return sum ? sum : "-";
    }
  };
  return (
    <>
      <section className={Styles.maintitle2}>{termtodisplay}</section>
      <section className={Styles.title}>SCHOLASTIC RECORD</section>
      {term === "anual" ? (
        <div
          className={type === "template" ? Styles.markContainer : ""}
          style={{
            height:
              gradeCategory === "Grade1to5"
                ? "318px"
                : gradeCategory === "Grade6" ||
                  gradeCategory === "Grade7" ||
                  gradeCategory === "Grade8"
                ? "344px"
                : "",
          }}
        >
          {gradeCategory === "Grade1to5" ||
          gradeCategory === "Grade6" ||
          gradeCategory === "Grade7" ||
          gradeCategory === "Grade8" ? (
            <div className={Styles.tableWrapper2}>
              <Watermark gradeCategory={gradeCategory} />
              <table className={Styles.tableBox}>
                <tbody>
                  {/* <tr className={Styles.tableHeader}>
                  <td className={Styles.leftColumn}>SUBJECT</td>
                  <td>
                    MARKS OBTAINED ({gradeCategory === "Grade1to5" ? 50 : 80})
                  </td>
                </tr> */}
                  <tr className={Styles.tableHeader}>
                    <td className={Styles.leftColumn}></td>
                    <td className={Styles.spannedHeader} colSpan={4}>
                      MARKS OBTAINED ({gradeCategory === "Grade1to5" ? 50 : 100}
                      )
                    </td>
                  </tr>
                  <tr className={Styles.tableHeader2}>
                    <td className={Styles.topalignedColumn} colSpan={1}>
                      <div>SUBJECT</div>
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      INTERNAL ASSESSMENT (
                      {gradeCategory === "Grade1to5" ? 10 : 20})
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      FINAL EXAM ({gradeCategory === "Grade1to5" ? 40 : 80})
                    </td>

                    <td className={Styles.leftcenteredColumn}>
                      TOTAL MARKS ({gradeCategory === "Grade1to5" ? 50 : 100})
                    </td>
                    <td> GRADE </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>ENGLISH</td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "ENGLISH") {
                          return <>{i.internal}</>;
                        }
                      })}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "ENGLISH") {
                          return <>{i.external}</>;
                        }
                      })}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "ENGLISH") {
                          return <>{i.total}</>;
                        }
                      })}
                    </td>
                    {gradeCategory === "Grade1to5" ? (
                      <td> {GetGrade1to5(GetMark("ENGLISH").total)}</td>
                    ) : (
                      <td> {GetGrade(GetMark("ENGLISH").total)}</td>
                    )}
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>HINDI</td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "HINDI") {
                          return <>{i.internal}</>;
                        }
                      })}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "HINDI") {
                          return <>{i.external}</>;
                        }
                      })}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "HINDI") {
                          return <>{i.total}</>;
                        }
                      })}
                    </td>
                    {gradeCategory === "Grade1to5" ? (
                      <td> {GetGrade1to5(GetMark("HINDI").total)}</td>
                    ) : (
                      <td> {GetGrade(GetMark("HINDI").total)}</td>
                    )}
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>KANNADA</td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "KANNADA") {
                          return <>{i.internal}</>;
                        }
                      })}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "KANNADA") {
                          return <>{i.external}</>;
                        }
                      })}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "KANNADA") {
                          return <>{i.total}</>;
                        }
                      })}
                    </td>
                    {gradeCategory === "Grade1to5" ? (
                      <td> {GetGrade1to5(GetMark("KANNADA").total)}</td>
                    ) : (
                      <td> {GetGrade(GetMark("KANNADA").total)}</td>
                    )}
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>MATHEMATICS</td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "MATHEMATICS") {
                          return <>{i.internal}</>;
                        }
                      })}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "MATHEMATICS") {
                          return <>{i.external}</>;
                        }
                      })}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "MATHEMATICS") {
                          return <>{i.total}</>;
                        }
                      })}
                    </td>
                    {gradeCategory === "Grade1to5" ? (
                      <td> {GetGrade1to5(GetMark("MATHEMATICS").total)}</td>
                    ) : (
                      <td> {GetGrade(GetMark("MATHEMATICS").total)}</td>
                    )}
                  </tr>

                  {/* <tr>
                  <td className={Styles.leftColumn}>ENGLISH</td>
                  <td>{marksList.map((i) => i.ENGLISH)}</td>
                </tr> */}

                  {/* <tr>
                  <td className={Styles.leftColumn}>HINDI</td>
                  <td>{marksList.map((i) => i.HINDI)}</td>
                </tr>
                <tr>
                  <td className={Styles.leftColumn}>KANNADA</td>
                  <td>{marksList.map((i) => i.KANNADA)}</td>
                </tr>
                <tr>
                  <td className={Styles.leftColumn}>MATHEMATICS</td>
                  <td>{marksList.map((i) => i.MATHEMATICS)}</td>
                </tr> */}

                  {gradeCategory === "Grade1to5" ? (
                    <>
                      {/* <tr>
                      <td className={Styles.leftColumn}>
                        ENVIRONMENTAL STUDIES
                      </td>
                      <td>
                        {marksList.map((i) => i["ENVIRONMENTAL STUDIES"])}
                      </td>
                    </tr> */}
                      <tr>
                        <td className={Styles.leftColumn}>
                          ENVIRONMENTAL STUDIES
                        </td>
                        <td className={Styles.leftcenteredColumn}>
                          {completeData.map((i) => {
                            if (i.subject_name === "ENVIRONMENTAL STUDIES") {
                              return <>{i.internal}</>;
                            }
                          })}
                        </td>
                        <td className={Styles.leftcenteredColumn}>
                          {completeData.map((i) => {
                            if (i.subject_name === "ENVIRONMENTAL STUDIES") {
                              return <>{i.external}</>;
                            }
                          })}
                        </td>
                        <td className={Styles.leftcenteredColumn}>
                          {completeData.map((i) => {
                            if (i.subject_name === "ENVIRONMENTAL STUDIES") {
                              return <>{i.total}</>;
                            }
                          })}
                        </td>
                        <td>
                          {GetGrade1to5(GetMark("ENVIRONMENTAL STUDIES").total)}
                        </td>
                      </tr>
                      <tr>
                        <td
                          className={Styles.leftColumn}
                          style={{ fontWeight: 700, color: "#000080" }}
                        >
                          GRAND TOTAL
                        </td>
                        <td
                          className={Styles.leftcenteredColumn}
                          style={{ fontWeight: 700 }}
                        >
                          {handleInternalTotal()}
                        </td>
                        <td
                          className={Styles.leftcenteredColumn}
                          style={{ fontWeight: 700 }}
                        >
                          {handleExternalTotal()}
                        </td>
                        <td
                          style={{ fontWeight: 700 }}
                          className={Styles.leftcenteredColumn}
                        >
                          {handleTotal()}
                        </td>
                      </tr>

                      {/* <tr>
                      <td
                        className={Styles.leftColumn}
                        style={{ fontWeight: 700, color: "#000080" }}
                      >
                        GRAND TOTAL
                      </td>
                      <tr>
                        <td
                          className={Styles.assesmentWidth}
                          style={{ fontWeight: 700 }}
                        >
                          {handleExternalTotal()}
                        </td>
                        <td
                          className={Styles.assesmentWidth}
                          style={{ fontWeight: 700 }}
                        >
                          {handleInternalTotal()}
                        </td>
                        <td style={{ fontWeight: 700 }}>{handleTotal()}</td>
                      </tr>
                    </tr> */}
                      {/* <tr>
                      <td className={Styles.grandTotal}>GRAND TOTAL</td>
                      <td style={{ fontWeight: 700 }}>{handleTotal()}</td>
                    </tr> */}
                    </>
                  ) : (
                    ""
                  )}

                  {gradeCategory === "Grade6" ||
                  gradeCategory === "Grade7" ||
                  gradeCategory === "Grade8" ? (
                    <>
                      {/* <tr>
                      <td className={Styles.leftColumn}>SCIENCE</td>
                      <td>{marksList.map((i) => i.SCIENCE)}</td>
                    </tr> */}
                      <tr>
                        <td className={Styles.leftColumn}>SCIENCE</td>
                        <td className={Styles.leftcenteredColumn}>
                          {completeData.map((i) => {
                            if (i.subject_name === "SCIENCE") {
                              return <>{i.internal}</>;
                            }
                          })}
                        </td>
                        <td className={Styles.leftcenteredColumn}>
                          {completeData.map((i) => {
                            if (i.subject_name === "SCIENCE") {
                              return <>{i.external}</>;
                            }
                          })}
                        </td>
                        <td className={Styles.leftcenteredColumn}>
                          {completeData.map((i) => {
                            if (i.subject_name === "SCIENCE") {
                              return <>{i.total}</>;
                            }
                          })}
                        </td>
                        <td>{GetGrade(GetMark("SCIENCE").total)}</td>
                      </tr>
                      {/* <tr>
                      <td className={Styles.leftColumn}>SOCIAL SCIENCE</td>
                      <td>{marksList.map((i) => i["SOCIAL SCIENCE"])}</td>
                    </tr> */}
                      <tr>
                        <td className={Styles.leftColumn}>SOCIAL SCIENCE</td>
                        <td className={Styles.leftcenteredColumn}>
                          {completeData.map((i) => {
                            if (i.subject_name === "SOCIAL SCIENCE") {
                              return <>{i.internal}</>;
                            }
                          })}
                        </td>
                        <td className={Styles.leftcenteredColumn}>
                          {completeData.map((i) => {
                            if (i.subject_name === "SOCIAL SCIENCE") {
                              return <>{i.external}</>;
                            }
                          })}
                        </td>
                        <td className={Styles.leftcenteredColumn}>
                          {completeData.map((i) => {
                            if (i.subject_name === "SOCIAL SCIENCE") {
                              return <>{i.total}</>;
                            }
                          })}
                        </td>
                        <td>{GetGrade(GetMark("SOCIAL SCIENCE").total)}</td>
                      </tr>
                      {/* <tr>
                      <td className={Styles.grandTotal}>GRAND TOTAL</td>
                      <td style={{ fontWeight: 700 }}>{handleTotal()}</td>
                    </tr> */}
                      <tr>
                        <td
                          className={Styles.leftColumn}
                          style={{ fontWeight: 700, color: "#000080" }}
                        >
                          GRAND TOTAL
                        </td>
                        <td
                          className={Styles.leftcenteredColumn}
                          style={{ fontWeight: 700 }}
                        >
                          {handleInternalTotal()}
                        </td>
                        <td
                          className={Styles.leftcenteredColumn}
                          style={{ fontWeight: 700 }}
                        >
                          {handleExternalTotal()}
                        </td>
                        <td
                          style={{ fontWeight: 700 }}
                          className={Styles.leftcenteredColumn}
                        >
                          {handleTotal()}
                        </td>
                      </tr>
                    </>
                  ) : (
                    ""
                  )}

                  {gradeCategory === "Grade1to5" ||
                  gradeCategory === "Grade6" ||
                  gradeCategory === "Grade8" ? (
                    <>
                      {/* <tr>
                      <td className={Styles.leftColumn}>VALUE EDUCATION</td>
                      <td>{marksList.map((i) => i["VALUE EDUCATION"])}</td>
                    </tr> */}
                      <tr>
                        <td className={Styles.leftColumn}>VALUE EDUCATION</td>
                        <td className={Styles.leftcenteredColumn}>
                          {completeData.map((i) => {
                            if (i.elective_name === "VALUE EDUCATION") {
                              return <>{i.internal}</>;
                            }
                          })}
                        </td>
                        <td className={Styles.leftcenteredColumn}>
                          {completeData.map((i) => {
                            if (i.elective_name === "VALUE EDUCATION") {
                              return <>{i.external}</>;
                            }
                          })}
                        </td>
                        <td className={Styles.leftcenteredColumn}>
                          {completeData.map((i) => {
                            if (i.elective_name === "VALUE EDUCATION") {
                              return <>{i.total}</>;
                            }
                          })}
                        </td>
                        {gradeCategory === "Grade1to5" ? (
                          <td>
                            {GetGrade1to5(GetMarks("VALUE EDUCATION").total)}
                          </td>
                        ) : (
                          <td>{GetGrade(GetMarks("VALUE EDUCATION").total)}</td>
                        )}
                      </tr>
                    </>
                  ) : (
                    ""
                  )}

                  {/* <tr>
                  <td className={Styles.leftColumn}>COMPUTER SCIENCE</td>
                  <td>{marksList.map((i) => i["COMPUTER SCIENCE"])}</td>
                </tr> */}

                  {gradeCategory === "Grade8" ? (
                    <tr>
                      <td className={Styles.leftColumn}>SKILL SUBJECT</td>
                      <td className={Styles.leftcenteredColumn}>
                        {completeData.map((i) => {
                          if (i.subject_name === "SKILL SUBJECT") {
                            return <>{i.internal}</>;
                          }
                        })}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {completeData.map((i) => {
                          if (i.subject_name === "SKILL SUBJECT") {
                            return <>{i.external}</>;
                          }
                        })}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {completeData.map((i) => {
                          if (i.subject_name === "SKILL SUBJECT") {
                            return <>{i.total}</>;
                          }
                        })}
                      </td>

                      <td> {GetGrade(GetMark("SKILL SUBJECT").total)}</td>
                    </tr>
                  ) : (
                    <tr>
                      <td className={Styles.leftColumn}>COMPUTER SCIENCE</td>
                      <td className={Styles.leftcenteredColumn}>
                        {completeData.map((i) => {
                          if (i.subject_name === "COMPUTER SCIENCE") {
                            return <>{i.internal}</>;
                          }
                        })}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {completeData.map((i) => {
                          if (i.subject_name === "COMPUTER SCIENCE") {
                            return <>{i.external}</>;
                          }
                        })}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {completeData.map((i) => {
                          if (i.subject_name === "COMPUTER SCIENCE") {
                            return <>{i.total}</>;
                          }
                        })}
                      </td>
                      {gradeCategory === "Grade1to5" ? (
                        <td>
                          {GetGrade1to5(GetMark("COMPUTER SCIENCE").total)}
                        </td>
                      ) : (
                        <td> {GetGrade(GetMark("COMPUTER SCIENCE").total)}</td>
                      )}
                    </tr>
                  )}

                  {/* <tr>
                  <td className={Styles.leftColumn}>GENERAL KNOWLEDGE</td>
                  <td>{marksList.map((i) => i["GENERAL KNOWLEDGE"])}</td>
                </tr> */}
                  <tr>
                    <td className={Styles.leftColumn}>GENERAL KNOWLEDGE</td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "GENERAL KNOWLEDGE") {
                          return <>{i.internal}</>;
                        }
                      })}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "GENERAL KNOWLEDGE") {
                          return <>{i.external}</>;
                        }
                      })}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "GENERAL KNOWLEDGE") {
                          return <>{i.total}</>;
                        }
                      })}
                    </td>
                    {gradeCategory === "Grade1to5" ? (
                      <td>
                        {GetGrade1to5(GetMark("GENERAL KNOWLEDGE").total)}
                      </td>
                    ) : (
                      <td> {GetGrade(GetMark("GENERAL KNOWLEDGE").total)}</td>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (gradeCategory === "Grade9" && term !== "anual") ||
            gradeCategory === "Grade10" ? (
            <div className={Styles.tableWrapper1}>
              <Watermark gradeCategory={gradeCategory} />
              <table className={Styles.tableBox}>
                <tbody>
                  <tr className={Styles.tableHeader}>
                    <td className={Styles.leftcenteredColumn}>SUB CODE</td>
                    <td className={Styles.leftColumn}>SUBJECT</td>
                    <td className={Styles.leftValues}>MARKS OBTAINED (80)</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftcenteredColumn}>
                      {subcodeList.map((i) => i["ENGLISH LAN & LIT."])}
                    </td>
                    <td className={Styles.leftColumn}>ENGLISH LAN & LIT.</td>
                    <td>{marksList.map((i) => i["ENGLISH LAN & LIT."])}</td>
                  </tr>
                  {/* <tr>
                                            <td className={Styles.leftcenteredColumn} >{subcodeList.map(i => i.KANNADA)}</td>
                                            <td className={Styles.leftColumn}>KANNADA</td>
                                            <td>{marksList.map(i => i.KANNADA)}</td>
                                        </tr> */}
                  <tr>
                    <td className={Styles.leftcenteredColumn}>
                      {subcodeList.map((i) => i.MATHEMATICS)}
                    </td>
                    <td className={Styles.leftColumn}>MATHEMATICS</td>
                    <td>{marksList.map((i) => i.MATHEMATICS)}</td>
                  </tr>
                  {marksList.filter((i) => i["Language"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftcenteredColumn}>
                        {subcodeList.map((i) => i.KANNADA)}
                      </td>
                      <td className={Styles.leftColumn}>KANNADA</td>
                      <td>{marksList.map((i) => i.KANNADA)}</td>
                    </tr>
                  ) : (
                    ""
                  )}
                  {marksList.filter((i) => i["HINDI COURSE-B"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftcenteredColumn}>
                        {subcodeList.map((i) => i["HINDI COURSE-B"])}
                      </td>
                      <td className={Styles.leftColumn}>HINDI COURSE-B</td>
                      <td>{marksList.map((i) => i["HINDI COURSE-B"])}</td>
                    </tr>
                  ) : (
                    ""
                  )}
                  <tr>
                    <td className={Styles.leftcenteredColumn}>
                      {subcodeList.map((i) => i.SCIENCE)}
                    </td>
                    <td className={Styles.leftColumn}>SCIENCE</td>
                    <td>{marksList.map((i) => i.SCIENCE)}</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftcenteredColumn}>
                      {subcodeList.map((i) => i["SOCIAL SCIENCE"])}
                    </td>
                    <td className={Styles.leftColumn}>SOCIAL SCIENCE</td>
                    <td>{marksList.map((i) => i["SOCIAL SCIENCE"])}</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftcenteredColumn}>
                      {subcodeList.map((i) => i["INFORMATION TECHNOLOGY"])}
                    </td>
                    <td className={Styles.leftColumn}>
                      INFORMATION TECHNOLOGY
                    </td>
                    <td>{marksList.map((i) => i["INFORMATION TECHNOLOGY"])}</td>
                  </tr>

                  <tr>
                    <td className={Styles.grandTotal} colSpan={2}>
                      GRAND TOTAL
                    </td>
                    <td style={{ fontWeight: 700 }}>{handleTotal()}</td>
                  </tr>
                  {gradeCategory === "Grade9" ? (
                    <>
                      <tr>
                        <td className={Styles.spannedColumn} colSpan={2}>
                          VALUE EDUCATION
                        </td>
                        <td>{marksList.map((i) => i["VALUE EDUCATION"])}</td>
                      </tr>
                      <tr>
                        <td className={Styles.spannedColumn} colSpan={2}>
                          GENERAL KNOWLEDGE
                        </td>
                        <td>{marksList.map((i) => i["GENERAL KNOWLEDGE"])}</td>
                      </tr>
                    </>
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
            </div>
          ) : ((gradeCategory.includes("Grade11") && term !== "anual") ||
              (gradeCategory.includes("Grade12") && term !== "anual")) &&
            gradeCategory.includes("Science") ? (
            <div className={Styles.tableWrapper2}>
              <Watermark gradeCategory={gradeCategory} />
              <table className={Styles.tableBox}>
                <tbody>
                  <tr className={Styles.tableHeader}>
                    <td className={Styles.leftColumn}></td>
                    <td className={Styles.leftColumn}></td>
                    <td className={Styles.spannedHeader} colSpan={3}>
                      MARKS OBTAINED
                    </td>
                  </tr>
                  <tr className={Styles.tableHeader2}>
                    <td className={Styles.topalignedColumn}>SUB CODE</td>
                    <td className={Styles.topalignedColumn}>
                      <div>SUBJECT</div>
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      THEORY (80/70)
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      INTERNAL ASSESSMENT / PRACTICAL (20/30)
                    </td>
                    <td>TOTAL MARKS (100)</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["ENGLISH CORE"])}
                    </td>
                    <td className={Styles.leftColumn}>ENGLISH CORE</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ENGLISH CORE"])
                        .map((j) => j["ENGLISH CORE"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ENGLISH CORE"])
                        .map((j) => j["ENGLISH CORE"][1])}
                    </td>
                    <td>
                      {marksList
                        .filter((i) => i["ENGLISH CORE"])
                        .map((j) => j["ENGLISH CORE"][2])}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["MATHEMATICS"])}
                    </td>
                    <td className={Styles.leftColumn}>MATHEMATICS</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["MATHEMATICS"])
                        .map((j) => j["MATHEMATICS"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["MATHEMATICS"])
                        .map((j) => j["MATHEMATICS"][1])}
                    </td>
                    <td>
                      {marksList
                        .filter((i) => i["MATHEMATICS"])
                        .map((j) => j["MATHEMATICS"][2])}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["PHYSICS"])}
                    </td>
                    <td className={Styles.leftColumn}>PHYSICS</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["PHYSICS"])
                        .map((j) => j["PHYSICS"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["PHYSICS"])
                        .map((j) => j["PHYSICS"][1])}
                    </td>
                    <td>
                      {marksList
                        .filter((i) => i["PHYSICS"])
                        .map((j) => j["PHYSICS"][2])}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["CHEMISTRY"])}
                    </td>
                    <td className={Styles.leftColumn}>CHEMISTRY</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["CHEMISTRY"])
                        .map((j) => j["CHEMISTRY"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["CHEMISTRY"])
                        .map((j) => j["CHEMISTRY"][1])}
                    </td>
                    <td>
                      {marksList
                        .filter((i) => i["CHEMISTRY"])
                        .map((j) => j["CHEMISTRY"][2])}
                    </td>
                  </tr>
                  {marksList.filter((i) => i["COMPUTER SCIENCE"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftColumn}>
                        {subcodeList.map((i) => i["COMPUTER SCIENCE"])}
                      </td>
                      <td className={Styles.leftColumn}>COMPUTER SCIENCE</td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["COMPUTER SCIENCE"])
                          .map((j) => j["COMPUTER SCIENCE"][0])}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["COMPUTER SCIENCE"])
                          .map((j) => j["COMPUTER SCIENCE"][1])}
                      </td>
                      <td>
                        {marksList
                          .filter((i) => i["COMPUTER SCIENCE"])
                          .map((j) => j["COMPUTER SCIENCE"][2])}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                  {marksList.filter((i) => i["BIOLOGY"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftColumn}>
                        {subcodeList.map((i) => i["BIOLOGY"])}
                      </td>
                      <td className={Styles.leftColumn}>BIOLOGY</td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["BIOLOGY"])
                          .map((j) => j["BIOLOGY"][0])}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["BIOLOGY"])
                          .map((j) => j["BIOLOGY"][1])}
                      </td>
                      <td>
                        {marksList
                          .filter((i) => i["BIOLOGY"])
                          .map((j) => j["BIOLOGY"][2])}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}

                  <tr>
                    <td className={Styles.grandTotal} colSpan={2}>
                      GRAND TOTAL
                    </td>
                    <td className={Styles.grandTotal1}>{handleTotal(0)}</td>
                    <td className={Styles.grandTotal1}>{handleTotal(1)}</td>
                    <td style={{ fontWeight: 700 }}>{handleTotal(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : ((gradeCategory.includes("Grade11") && term !== "anual") ||
              (gradeCategory.includes("Grade12") && term !== "anual")) &&
            gradeCategory.includes("Commerce") ? (
            <div className={Styles.tableWrapper2}>
              <Watermark gradeCategory={gradeCategory} />
              <table className={Styles.tableBox}>
                <tbody>
                  <tr className={Styles.tableHeader}>
                    <td className={Styles.leftColumn}></td>
                    <td className={Styles.leftColumn}></td>
                    <td className={Styles.spannedHeader} colSpan={3}>
                      MARKS OBTAINED
                    </td>
                  </tr>
                  <tr className={Styles.tableHeader2}>
                    <td className={Styles.topalignedColumn}>SUB CODE</td>
                    <td className={Styles.topalignedColumn}>
                      <div>SUBJECT</div>
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      THEORY (80/70)
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      INTERNAL ASSESSMENT / PRACTICAL (20/30)
                    </td>
                    <td>TOTAL MARKS (100)</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["ENGLISH CORE"])}
                    </td>
                    <td className={Styles.leftColumn}>ENGLISH CORE</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ENGLISH CORE"])
                        .map((j) => j["ENGLISH CORE"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ENGLISH CORE"])
                        .map((j) => j["ENGLISH CORE"][1])}
                    </td>
                    <td>
                      {marksList
                        .filter((i) => i["ENGLISH CORE"])
                        .map((j) => j["ENGLISH CORE"][2])}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["ECONOMICS"])}
                    </td>
                    <td className={Styles.leftColumn}>ECONOMICS</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ECONOMICS"])
                        .map((j) => j["ECONOMICS"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ECONOMICS"])
                        .map((j) => j["ECONOMICS"][1])}
                    </td>
                    <td>
                      {marksList
                        .filter((i) => i["ECONOMICS"])
                        .map((j) => j["ECONOMICS"][2])}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["BUSINESS STUDIES"])}
                    </td>
                    <td className={Styles.leftColumn}>BUSINESS STUDIES</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["BUSINESS STUDIES"])
                        .map((j) => j["BUSINESS STUDIES"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["BUSINESS STUDIES"])
                        .map((j) => j["BUSINESS STUDIES"][1])}
                    </td>
                    <td>
                      {marksList
                        .filter((i) => i["BUSINESS STUDIES"])
                        .map((j) => j["BUSINESS STUDIES"][2])}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["ACCOUNTANCY"])}
                    </td>
                    <td className={Styles.leftColumn}>ACCOUNTANCY</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ACCOUNTANCY"])
                        .map((j) => j["ACCOUNTANCY"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ACCOUNTANCY"])
                        .map((j) => j["ACCOUNTANCY"][1])}
                    </td>
                    <td>
                      {marksList
                        .filter((i) => i["ACCOUNTANCY"])
                        .map((j) => j["ACCOUNTANCY"][2])}
                    </td>
                  </tr>
                  {marksList.filter((i) => i["COMPUTER SCIENCE"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftColumn}>
                        {subcodeList.map((i) => i["COMPUTER SCIENCE"])}
                      </td>
                      <td className={Styles.leftColumn}>COMPUTER SCIENCE</td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["COMPUTER SCIENCE"])
                          .map((j) => j["COMPUTER SCIENCE"][0])}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["COMPUTER SCIENCE"])
                          .map((j) => j["COMPUTER SCIENCE"][1])}
                      </td>
                      <td>
                        {marksList
                          .filter((i) => i["COMPUTER SCIENCE"])
                          .map((j) => j["COMPUTER SCIENCE"][2])}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                  {marksList.filter((i) => i["MATHEMATICS"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftColumn}>
                        {subcodeList.map((i) => i["MATHEMATICS"])}
                      </td>
                      <td className={Styles.leftColumn}>MATHEMATICS</td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["MATHEMATICS"])
                          .map((j) => j["MATHEMATICS"][0])}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["MATHEMATICS"])
                          .map((j) => j["MATHEMATICS"][1])}
                      </td>
                      <td>
                        {marksList
                          .filter((i) => i["MATHEMATICS"])
                          .map((j) => j["MATHEMATICS"][2])}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                  <tr>
                    <td className={Styles.grandTotal} colSpan={2}>
                      GRAND TOTAL
                    </td>
                    <td className={Styles.grandTotal1}>{handleTotal(0)}</td>
                    <td className={Styles.grandTotal1}>{handleTotal(1)}</td>
                    <td style={{ fontWeight: 700 }}>{handleTotal(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : gradeCategory.includes("Grade12") &&
            term === "anual" &&
            gradeCategory.includes("Commerce") ? (
            <div className={Styles.tableWrapper2}>
              <Watermark gradeCategory={gradeCategory} />
              <table className={Styles.tableBox}>
                <tbody>
                  <tr className={Styles.tableHeader2}>
                    <td className={Styles.leftcenteredColumn}>SUB CODE</td>
                    <td className={Styles.leftColumn}>SUBJECT</td>
                    <td className={Styles.leftValues}>
                      MARKS OBTAINED (80/70)
                    </td>
                  </tr>

                  <tr>
                    <td className={Styles.leftcenteredColumn}>
                      {subcodeList.map((i) => i["ENGLISH CORE"])}
                    </td>
                    <td className={Styles.leftColumn}>ENGLISH CORE</td>
                    {/* <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['ENGLISH CORE']).map(j => j['ENGLISH CORE'][0])}</td>
                                            <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['ENGLISH CORE']).map(j => j['ENGLISH CORE'][1])}</td> */}
                    <td>
                      {marksList
                        .filter((i) => i["ENGLISH CORE"])
                        .map((j) => j["ENGLISH CORE"][2])}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftcenteredColumn}>
                      {subcodeList.map((i) => i["ECONOMICS"])}
                    </td>
                    <td className={Styles.leftColumn}>ECONOMICS</td>
                    {/* <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['ECONOMICS']).map(j => j['ECONOMICS'][0])}</td>
                                            <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['ECONOMICS']).map(j => j['ECONOMICS'][1])}</td> */}
                    <td>
                      {marksList
                        .filter((i) => i["ECONOMICS"])
                        .map((j) => j["ECONOMICS"][2])}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftcenteredColumn}>
                      {subcodeList.map((i) => i["BUSINESS STUDIES"])}
                    </td>
                    <td className={Styles.leftColumn}>BUSINESS STUDIES</td>
                    {/* <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['BUSINESS STUDIES']).map(j => j['BUSINESS STUDIES'][0])}</td>
                                            <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['BUSINESS STUDIES']).map(j => j['BUSINESS STUDIES'][1])}</td> */}
                    <td>
                      {marksList
                        .filter((i) => i["BUSINESS STUDIES"])
                        .map((j) => j["BUSINESS STUDIES"][2])}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftcenteredColumn}>
                      {subcodeList.map((i) => i["ACCOUNTANCY"])}
                    </td>
                    <td className={Styles.leftColumn}>ACCOUNTANCY</td>
                    {/* <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['ACCOUNTANCY']).map(j => j['ACCOUNTANCY'][0])}</td>
                                            <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['ACCOUNTANCY']).map(j => j['ACCOUNTANCY'][1])}</td> */}
                    <td>
                      {marksList
                        .filter((i) => i["ACCOUNTANCY"])
                        .map((j) => j["ACCOUNTANCY"][2])}
                    </td>
                  </tr>
                  {marksList.filter((i) => i["COMPUTER SCIENCE"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftcenteredColumn}>
                        {subcodeList.map((i) => i["COMPUTER SCIENCE"])}
                      </td>
                      <td className={Styles.leftColumn}>COMPUTER SCIENCE</td>
                      {/* <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['COMPUTER SCIENCE']).map(j => j['COMPUTER SCIENCE'][0])}</td>
                                                <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['COMPUTER SCIENCE']).map(j => j['COMPUTER SCIENCE'][1])}</td> */}
                      <td>
                        {marksList
                          .filter((i) => i["COMPUTER SCIENCE"])
                          .map((j) => j["COMPUTER SCIENCE"][2])}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                  {marksList.filter((i) => i["MATHEMATICS"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftcenteredColumn}>
                        {subcodeList.map((i) => i["MATHEMATICS"])}
                      </td>
                      <td className={Styles.leftColumn}>MATHEMATICS</td>
                      {/* <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['MATHEMATICS']).map(j => j['MATHEMATICS'][0])}</td>
                                                <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['MATHEMATICS']).map(j => j['MATHEMATICS'][1])}</td> */}
                      <td>
                        {marksList
                          .filter((i) => i["MATHEMATICS"])
                          .map((j) => j["MATHEMATICS"][2])}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                  <tr>
                    <td className={Styles.grandTotal} colSpan={2}>
                      GRAND TOTAL
                    </td>
                    {/* <td className={Styles.grandTotal1}>{handleTotal(0)}</td>
                                            <td className={Styles.grandTotal1}>{handleTotal(1)}</td> */}
                    <td style={{ fontWeight: 700 }}>{handleTotal(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : gradeCategory.includes("Grade12") &&
            term === "anual" &&
            gradeCategory.includes("Science") ? (
            <div className={Styles.tableWrapper2}>
              <Watermark gradeCategory={gradeCategory} />
              <table className={Styles.tableBox}>
                <tbody>
                  <tr className={Styles.tableHeader2}>
                    <td className={Styles.leftcenteredColumn}>SUB CODE</td>
                    <td className={Styles.leftColumn}>
                      <div>SUBJECT</div>
                    </td>
                    <td className={Styles.leftValues}>
                      MARKS OBTAINED (80/70)
                    </td>
                  </tr>
                  {/* <tr className={Styles.tableHeader2}>
                                            <td className={Styles.topalignedColumn}>SUB CODE</td>
                                            <td className={Styles.topalignedColumn}><div>SUBJECT</div></td>
                                            <td className={Styles.leftcenteredColumn} >THEORY (80/70)</td>
                                            <td className={Styles.leftcenteredColumn} >INTERNAL ASSESSMENT / PRACTICAL (20/30)</td>
                                            <td>TOTAL MARKS (100)</td>
                                        </tr> */}
                  <tr>
                    <td className={Styles.leftcenteredColumn}>
                      {subcodeList.map((i) => i["ENGLISH CORE"])}
                    </td>
                    <td className={Styles.leftColumn}>ENGLISH CORE</td>
                    {/* <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['ENGLISH CORE']).map(j => j['ENGLISH CORE'][0])}</td>
                                            <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['ENGLISH CORE']).map(j => j['ENGLISH CORE'][1])}</td> */}
                    <td>
                      {marksList
                        .filter((i) => i["ENGLISH CORE"])
                        .map((j) => j["ENGLISH CORE"][2])}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftcenteredColumn}>
                      {subcodeList.map((i) => i["MATHEMATICS"])}
                    </td>
                    <td className={Styles.leftColumn}>MATHEMATICS</td>
                    {/* <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['MATHEMATICS']).map(j => j['MATHEMATICS'][0])}</td>
                                            <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['MATHEMATICS']).map(j => j['MATHEMATICS'][1])}</td> */}
                    <td>
                      {marksList
                        .filter((i) => i["MATHEMATICS"])
                        .map((j) => j["MATHEMATICS"][2])}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftcenteredColumn}>
                      {subcodeList.map((i) => i["PHYSICS"])}
                    </td>
                    <td className={Styles.leftColumn}>PHYSICS</td>
                    {/* <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['PHYSICS']).map(j => j['PHYSICS'][0])}</td>
                                            <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['PHYSICS']).map(j => j['PHYSICS'][1])}</td> */}
                    <td>
                      {marksList
                        .filter((i) => i["PHYSICS"])
                        .map((j) => j["PHYSICS"][2])}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftcenteredColumn}>
                      {subcodeList.map((i) => i["CHEMISTRY"])}
                    </td>
                    <td className={Styles.leftColumn}>CHEMISTRY</td>
                    {/* <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['CHEMISTRY']).map(j => j['CHEMISTRY'][0])}</td>
                                            <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['CHEMISTRY']).map(j => j['CHEMISTRY'][1])}</td> */}
                    <td>
                      {marksList
                        .filter((i) => i["CHEMISTRY"])
                        .map((j) => j["CHEMISTRY"][2])}
                    </td>
                  </tr>
                  {marksList.filter((i) => i["COMPUTER SCIENCE"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftcenteredColumn}>
                        {subcodeList.map((i) => i["COMPUTER SCIENCE"])}
                      </td>
                      <td className={Styles.leftColumn}>COMPUTER SCIENCE</td>
                      {/* <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['COMPUTER SCIENCE']).map(j => j['COMPUTER SCIENCE'][0])}</td>
                                                <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['COMPUTER SCIENCE']).map(j => j['COMPUTER SCIENCE'][1])}</td> */}
                      <td>
                        {marksList
                          .filter((i) => i["COMPUTER SCIENCE"])
                          .map((j) => j["COMPUTER SCIENCE"][2])}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                  {marksList.filter((i) => i["BIOLOGY"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftcenteredColumn}>
                        {subcodeList.map((i) => i["BIOLOGY"])}
                      </td>
                      <td className={Styles.leftColumn}>BIOLOGY</td>
                      {/* <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['BIOLOGY']).map(j => j['BIOLOGY'][0])}</td>
                                                <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['BIOLOGY']).map(j => j['BIOLOGY'][1])}</td> */}
                      <td>
                        {marksList
                          .filter((i) => i["BIOLOGY"])
                          .map((j) => j["BIOLOGY"][2])}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}

                  <tr>
                    <td className={Styles.grandTotal} colSpan={2}>
                      GRAND TOTAL
                    </td>
                    {/* <td className={Styles.grandTotal1}>{handleTotal(0)}</td>
                                            <td className={Styles.grandTotal1}>{handleTotal(1)}</td> */}
                    <td style={{ fontWeight: 700 }}>{handleTotal(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : gradeCategory.includes("Grade11") &&
            term === "anual" &&
            gradeCategory.includes("Science") ? (
            <div className={Styles.tableWrapper2}>
              <Watermark gradeCategory={gradeCategory} />
              <table className={Styles.tableBox}>
                <tbody>
                  <tr className={Styles.tableHeader}>
                    <td className={Styles.leftColumn}></td>
                    <td className={Styles.leftColumn}></td>
                    <td className={Styles.spannedHeader} colSpan={4}>
                      MARKS OBTAINED
                    </td>
                  </tr>
                  <tr className={Styles.tableHeader2}>
                    <td className={Styles.topalignedColumn}>SUB CODE</td>
                    <td className={Styles.topalignedColumn}>
                      <div>SUBJECT</div>
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      INTERNAL ASSESSMENT / PRACTICAL (20/30)
                    </td>
                    <td
                      style={{ "text-align": "center" }}
                      className={Styles.leftcenteredColumn}
                    >
                      THEORY(80 / 70 )
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      TOTAL MARKS (100)
                    </td>
                    <td>GRADE</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["ENGLISH CORE"])}
                    </td>
                    <td className={Styles.leftColumn}>ENGLISH CORE</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ENGLISH CORE"])
                        .map((j) => j["ENGLISH CORE"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ENGLISH CORE"])
                        .map((j) => j["ENGLISH CORE"][1])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ENGLISH CORE"])
                        .map((j) => j["ENGLISH CORE"][2])}
                    </td>
                    <td>
                      {GetGrade(
                        marksList
                          .filter((i) => i["ENGLISH CORE"])
                          .map((j) => j["ENGLISH CORE"][2])
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["MATHEMATICS"])}
                    </td>
                    <td className={Styles.leftColumn}>MATHEMATICS</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["MATHEMATICS"])
                        .map((j) => j["MATHEMATICS"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["MATHEMATICS"])
                        .map((j) => j["MATHEMATICS"][1])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["MATHEMATICS"])
                        .map((j) => j["MATHEMATICS"][2])}
                    </td>
                    <td>
                      {GetGrade(
                        marksList
                          .filter((i) => i["MATHEMATICS"])
                          .map((j) => j["MATHEMATICS"][2])
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["PHYSICS"])}
                    </td>
                    <td className={Styles.leftColumn}>PHYSICS</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["PHYSICS"])
                        .map((j) => j["PHYSICS"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["PHYSICS"])
                        .map((j) => j["PHYSICS"][1])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["PHYSICS"])
                        .map((j) => j["PHYSICS"][2])}
                    </td>
                    <td>
                      {GetGrade(
                        marksList
                          .filter((i) => i["PHYSICS"])
                          .map((j) => j["PHYSICS"][2])
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["CHEMISTRY"])}
                    </td>
                    <td className={Styles.leftColumn}>CHEMISTRY</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["CHEMISTRY"])
                        .map((j) => j["CHEMISTRY"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["CHEMISTRY"])
                        .map((j) => j["CHEMISTRY"][1])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["CHEMISTRY"])
                        .map((j) => j["CHEMISTRY"][2])}
                    </td>
                    <td>
                      {GetGrade(
                        marksList
                          .filter((i) => i["CHEMISTRY"])
                          .map((j) => j["CHEMISTRY"][2])
                      )}
                    </td>
                  </tr>
                  {marksList.filter((i) => i["COMPUTER SCIENCE"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftColumn}>
                        {subcodeList.map((i) => i["COMPUTER SCIENCE"])}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        COMPUTER SCIENCE
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["COMPUTER SCIENCE"])
                          .map((j) => j["COMPUTER SCIENCE"][0])}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["COMPUTER SCIENCE"])
                          .map((j) => j["COMPUTER SCIENCE"][1])}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["COMPUTER SCIENCE"])
                          .map((j) => j["COMPUTER SCIENCE"][2])}
                      </td>
                      <td>
                        {GetGrade(
                          marksList
                            .filter((i) => i["COMPUTER SCIENCE"])
                            .map((j) => j["COMPUTER SCIENCE"][2])
                        )}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                  {marksList.filter((i) => i["BIOLOGY"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftColumn}>
                        {subcodeList.map((i) => i["BIOLOGY"])}
                      </td>
                      <td className={Styles.leftColumn}>BIOLOGY</td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["BIOLOGY"])
                          .map((j) => j["BIOLOGY"][0])}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["BIOLOGY"])
                          .map((j) => j["BIOLOGY"][1])}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["BIOLOGY"])
                          .map((j) => j["BIOLOGY"][2])}
                      </td>
                      <td>
                        {GetGrade(
                          marksList
                            .filter((i) => i["BIOLOGY"])
                            .map((j) => j["BIOLOGY"][2])
                        )}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}

                  <tr>
                    <td className={Styles.grandTotal} colSpan={2}>
                      GRAND TOTAL
                    </td>
                    <td className={Styles.grandTotal1}>{handleTotal(0)}</td>
                    <td className={Styles.grandTotal1}>{handleTotal(1)}</td>
                    <td
                      className={Styles.leftcenteredColumn}
                      style={{ fontWeight: 700 }}
                    >
                      {handleTotal(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : gradeCategory.includes("Grade11") &&
            term === "anual" &&
            gradeCategory.includes("Commerce") ? (
            <div className={Styles.tableWrapper2}>
              <Watermark gradeCategory={gradeCategory} />
              <table className={Styles.tableBox}>
                <tbody>
                  <tr className={Styles.tableHeader}>
                    <td className={Styles.leftColumn}></td>
                    <td className={Styles.leftColumn}></td>
                    <td className={Styles.spannedHeader} colSpan={4}>
                      MARKS OBTAINED
                    </td>
                  </tr>
                  <tr className={Styles.tableHeader2}>
                    <td className={Styles.topalignedColumn}>SUB CODE</td>
                    <td className={Styles.topalignedColumn}>
                      <div>SUBJECT</div>
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      INTERNAL ASSESSMENT / PRACTICAL (20/30)
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      THEORY ( 80 / 70 )
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      TOTAL MARKS (100)
                    </td>
                    <td>Grade</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["ENGLISH CORE"])}
                    </td>
                    <td className={Styles.leftColumn}>ENGLISH CORE</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ENGLISH CORE"])
                        .map((j) => j["ENGLISH CORE"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ENGLISH CORE"])
                        .map((j) => j["ENGLISH CORE"][1])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ENGLISH CORE"])
                        .map((j) => j["ENGLISH CORE"][2])}
                    </td>
                    <td>
                      {GetGrade(
                        marksList
                          .filter((i) => i["ENGLISH CORE"])
                          .map((j) => j["ENGLISH CORE"][2])
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["ECONOMICS"])}
                    </td>
                    <td className={Styles.leftColumn}>ECONOMICS</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ECONOMICS"])
                        .map((j) => j["ECONOMICS"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ECONOMICS"])
                        .map((j) => j["ECONOMICS"][1])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ECONOMICS"])
                        .map((j) => j["ECONOMICS"][2])}
                    </td>
                    <td>
                      {GetGrade(
                        marksList
                          .filter((i) => i["ECONOMICS"])
                          .map((j) => j["ECONOMICS"][2])
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["BUSINESS STUDIES"])}
                    </td>
                    <td className={Styles.leftColumn}>BUSINESS STUDIES</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["BUSINESS STUDIES"])
                        .map((j) => j["BUSINESS STUDIES"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["BUSINESS STUDIES"])
                        .map((j) => j["BUSINESS STUDIES"][1])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["BUSINESS STUDIES"])
                        .map((j) => j["BUSINESS STUDIES"][2])}
                    </td>
                    <td>
                      {GetGrade(
                        marksList
                          .filter((i) => i["BUSINESS STUDIES"])
                          .map((j) => j["BUSINESS STUDIES"][2])
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["ACCOUNTANCY"])}
                    </td>
                    <td className={Styles.leftColumn}>ACCOUNTANCY</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ACCOUNTANCY"])
                        .map((j) => j["ACCOUNTANCY"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ACCOUNTANCY"])
                        .map((j) => j["ACCOUNTANCY"][1])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ACCOUNTANCY"])
                        .map((j) => j["ACCOUNTANCY"][2])}
                    </td>
                    <td>
                      {GetGrade(
                        marksList
                          .filter((i) => i["ACCOUNTANCY"])
                          .map((j) => j["ACCOUNTANCY"][2])
                      )}
                    </td>
                  </tr>
                  {marksList.filter((i) => i["COMPUTER SCIENCE"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftColumn}>
                        {subcodeList.map((i) => i["COMPUTER SCIENCE"])}
                      </td>
                      <td className={Styles.leftColumn}>COMPUTER SCIENCE</td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["COMPUTER SCIENCE"])
                          .map((j) => j["COMPUTER SCIENCE"][0])}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["COMPUTER SCIENCE"])
                          .map((j) => j["COMPUTER SCIENCE"][1])}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["COMPUTER SCIENCE"])
                          .map((j) => j["COMPUTER SCIENCE"][2])}
                      </td>
                      <td>
                        {GetGrade(
                          marksList
                            .filter((i) => i["COMPUTER SCIENCE"])
                            .map((j) => j["COMPUTER SCIENCE"][2])
                        )}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                  {marksList.filter((i) => i["MATHEMATICS"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftColumn}>
                        {subcodeList.map((i) => i["MATHEMATICS"])}
                      </td>
                      <td className={Styles.leftColumn}>MATHEMATICS</td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["MATHEMATICS"])
                          .map((j) => j["MATHEMATICS"][0])}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["MATHEMATICS"])
                          .map((j) => j["MATHEMATICS"][1])}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["MATHEMATICS"])
                          .map((j) => j["MATHEMATICS"][2])}
                      </td>
                      <td>
                        {GetGrade(
                          marksList
                            .filter((i) => i["MATHEMATICS"])
                            .map((j) => j["MATHEMATICS"][2])
                        )}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                  <tr>
                    <td className={Styles.grandTotal} colSpan={2}>
                      GRAND TOTAL
                    </td>
                    <td className={Styles.grandTotal1}>
                      {handleTotalNewfor11("internal")}
                    </td>
                    <td className={Styles.grandTotal1}>
                      {handleTotalNewfor11("external")}
                    </td>
                    <td
                      className={Styles.leftcenteredColumn}
                      style={{ fontWeight: 700 }}
                    >
                      {handleTotalNewfor11("total")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : gradeCategory === "Grade9" && term === "anual" ? (
            <div
              style={{ "margin-bottom": "70px" }}
              className={Styles.tableWrapper2}
            >
              <Watermark gradeCategory={gradeCategory} />
              <table className={Styles.tableBox}>
                <tbody>
                  <tr className={Styles.tableHeader}>
                    <td className={Styles.leftColumn}></td>
                    <td className={Styles.leftColumn}></td>
                    <td className={Styles.spannedHeader} colSpan={4}>
                      MARKS OBTAINED
                    </td>
                  </tr>
                  <tr className={Styles.tableHeader2}>
                    <td className={Styles.topalignedColumn}>SUB CODE</td>
                    <td className={Styles.topalignedColumn}>
                      <div>SUBJECT</div>
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      INTERNAL ASSESSMENT ( 20 )
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      FINAL EXAM ( 80 )
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      TOTAL MARKS (100)
                    </td>
                    <td>Grade</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["ENGLISH LAN & LIT."])}
                    </td>
                    <td className={Styles.leftColumn}>ENGLISH LAN & LIT.</td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("ENGLISH LAN & LIT.").internal}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("ENGLISH LAN & LIT.").external}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("ENGLISH LAN & LIT.").total}
                    </td>
                    <td>{GetGrade(GetMark("ENGLISH LAN & LIT.").total)}</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["MATHEMATICS"])}
                    </td>
                    <td className={Styles.leftColumn}>MATHEMATICS</td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("MATHEMATICS").internal}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("MATHEMATICS").external}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("MATHEMATICS").total}
                    </td>
                    <td>{GetGrade(GetMark("MATHEMATICS").total)}</td>
                  </tr>

                  <tr>
                    <td className={Styles.leftColumn}>
                      {completeData.map((i) =>
                        i.subject_name == "KANNADA" ||
                        i.subject_name == "HINDI COURSE-B"
                          ? i.subject_code
                          : ""
                      )}
                    </td>
                    <td className={Styles.leftColumn}>
                      {completeData.map((i) =>
                        i.subject_name == "KANNADA" ||
                        i.subject_name == "HINDI COURSE-B"
                          ? i.subject_name
                          : ""
                      )}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) =>
                        i.subject_name == "KANNADA" ||
                        i.subject_name == "HINDI COURSE-B"
                          ? GetMark(i.subject_name).internal
                          : ""
                      )}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) =>
                        i.subject_name == "KANNADA" ||
                        i.subject_name == "HINDI COURSE-B"
                          ? GetMark(i.subject_name).external
                          : ""
                      )}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) =>
                        i.subject_name == "KANNADA" ||
                        i.subject_name == "HINDI COURSE-B"
                          ? GetMark(i.subject_name).total
                          : ""
                      )}
                    </td>
                    <td>
                      {completeData.map((i) =>
                        i.subject_name == "KANNADA" ||
                        i.subject_name == "HINDI COURSE-B"
                          ? GetGrade(GetMark(i.subject_name).total)
                          : ""
                      )}
                    </td>
                  </tr>

                  {/* {marksList.filter((i) => i["HINDI COURSE-B"]).length > 0 ? (
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["HINDI COURSE-B"])}
                    </td>
                    <td className={Styles.leftColumn}>HINDI COURSE-B</td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("HINDI COURSE-B").internal}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("HINDI COURSE-B").external}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("HINDI COURSE-B").total}
                    </td>
                    <td>{GetGrade(GetMark("HINDI COURSE-B").total)}</td>
                  </tr>
                ) : (
                  ""
                )} */}

                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["SCIENCE"])}
                    </td>
                    <td className={Styles.leftColumn}>SCIENCE</td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("SCIENCE").internal}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("SCIENCE").external}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("SCIENCE").total}
                    </td>
                    <td>{GetGrade(GetMark("SCIENCE").total)}</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["SOCIAL SCIENCE"])}
                    </td>
                    <td className={Styles.leftColumn}>SOCIAL SCIENCE</td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("SOCIAL SCIENCE").internal}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("SOCIAL SCIENCE").external}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("SOCIAL SCIENCE").total}
                    </td>
                    <td>{GetGrade(GetMark("SOCIAL SCIENCE").total)}</td>
                  </tr>
                  {/* <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["INFORMATION TECHNOLOGY"])}
                    </td>
                    <td className={Styles.leftColumn}>
                      INFORMATION TECHNOLOGY
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("INFORMATION TECHNOLOGY").internal}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("INFORMATION TECHNOLOGY").external}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("INFORMATION TECHNOLOGY").total}
                    </td>
                    <td>{GetGrade(GetMark("INFORMATION TECHNOLOGY").total)}</td>
                  </tr> */}
                  <tr>
                    <td className={Styles.grandTotal} colSpan={2}>
                      GRAND TOTAL
                    </td>
                    <td className={Styles.grandTotal1}>
                      {handleTotalNew("internal")}
                    </td>
                    <td className={Styles.grandTotal1}>
                      {handleTotalNew("external")}
                    </td>
                    <td
                      className={Styles.leftcenteredColumn}
                      style={{ fontWeight: 700 }}
                    >
                      {handleTotalNew("total")}
                    </td>
                  </tr>
                  {gradeCategory === "Grade9" ? (
                    <>
                      <tr>
                        <td className={Styles.spannedColumn} colSpan={2}>
                          VALUE EDUCATION
                        </td>
                        <td className={Styles.leftcenteredColumn}>
                          {completeData.map((i) =>
                            i.subject_name == "MORAL SCIENCE" ||
                            i.subject_name == "CATECHISM"
                              ? GetMark(i.subject_name).internal
                              : ""
                          )}
                        </td>
                        <td className={Styles.leftcenteredColumn}>
                          {completeData.map((i) =>
                            i.subject_name == "MORAL SCIENCE" ||
                            i.subject_name == "CATECHISM"
                              ? GetMark(i.subject_name).external
                              : ""
                          )}
                        </td>
                        <td className={Styles.leftcenteredColumn}>
                          {completeData.map((i) =>
                            i.subject_name == "MORAL SCIENCE" ||
                            i.subject_name == "CATECHISM"
                              ? GetMark(i.subject_name).total
                              : ""
                          )}
                        </td>
                        <td>
                          {completeData.map((i) =>
                            i.subject_name == "MORAL SCIENCE" ||
                            i.subject_name == "CATECHISM"
                              ? GetGrade(GetMark(i.subject_name).total)
                              : ""
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className={Styles.spannedColumn} colSpan={2}>
                          GENERAL KNOWLEDGE
                        </td>
                        <td className={Styles.leftcenteredColumn}>
                          {GetMark("GENERAL KNOWLEDGE")?.internal}
                        </td>
                        <td className={Styles.leftcenteredColumn}>
                          {GetMark("GENERAL KNOWLEDGE")?.external}
                        </td>
                        <td className={Styles.leftcenteredColumn}>
                          {GetMark("GENERAL KNOWLEDGE")?.total}
                        </td>
                        <td>{GetGrade(GetMark("GENERAL KNOWLEDGE")?.total)}</td>
                      </tr>
                      <tr>
                        <td className={Styles.spannedColumn} colSpan={2}>
                          SKILL SUBJECT
                        </td>
                        <td className={Styles.leftcenteredColumn}>
                          {completeData.map((i) => {
                            if (i.subject_name === "SKILL SUBJECT") {
                              return <>{i.internal}</>;
                            }
                          })}
                        </td>
                        <td className={Styles.leftcenteredColumn}>
                          {completeData.map((i) => {
                            if (i.subject_name === "SKILL SUBJECT") {
                              return <>{i.external}</>;
                            }
                          })}
                        </td>
                        <td className={Styles.leftcenteredColumn}>
                          {completeData.map((i) => {
                            if (i.subject_name === "SKILL SUBJECT") {
                              return <>{i.total}</>;
                            }
                          })}
                        </td>
                        <td> {GetGrade(GetMark("SKILL SUBJECT").total)}</td>
                      </tr>
                    </>
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div
          style={{
            height:
              gradeCategory === "Grade1to5"
                ? "302px"
                : gradeCategory === "Grade7" || gradeCategory === "Grade10"
                ? "284px"
                : "",
          }}
          className={type === "template" ? Styles.markContainer : ""}
        >
          {gradeCategory === "Grade1to5" ? (
            <div className={Styles.tableWrapper2}>
              <Watermark gradeCategory={gradeCategory} />
              <table className={Styles.tableBox}>
                <tbody>
                  <tr className={Styles.tableHeader}>
                    <td className={Styles.leftColumn}></td>
                    <td className={Styles.spannedHeader} colSpan={4}>
                      MARKS OBTAINED (50)
                    </td>
                  </tr>
                  <tr className={Styles.tableHeader2}>
                    <td className={Styles.topalignedColumn} colSpan={1}>
                      <div>SUBJECT</div>
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      INTERNAL ASSESSMENT (10)
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      FINAL EXAM (40)
                    </td>

                    <td className={Styles.leftcenteredColumn}>
                      TOTAL MARKS (50)
                    </td>
                    <td> GRADE </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>ENGLISH</td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "ENGLISH") {
                          return <>{i.internal}</>;
                        }
                      })}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "ENGLISH") {
                          return <>{i.external}</>;
                        }
                      })}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "ENGLISH") {
                          return <>{i.total}</>;
                        }
                      })}
                    </td>
                    <td>{GetGrade1to5(GetMark("ENGLISH").total)}</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>HINDI</td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "HINDI") {
                          return <>{i.internal}</>;
                        }
                      })}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "HINDI") {
                          return <>{i.external}</>;
                        }
                      })}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "HINDI") {
                          return <>{i.total}</>;
                        }
                      })}
                    </td>
                    <td>{GetGrade1to5(GetMark("HINDI").total)}</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>KANNADA</td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "KANNADA") {
                          return <>{i.internal}</>;
                        }
                      })}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "KANNADA") {
                          return <>{i.external}</>;
                        }
                      })}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "KANNADA") {
                          return <>{i.total}</>;
                        }
                      })}
                    </td>
                    <td>{GetGrade1to5(GetMark("KANNADA").total)}</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>MATHEMATICS</td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "MATHEMATICS") {
                          return <>{i.internal}</>;
                        }
                      })}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "MATHEMATICS") {
                          return <>{i.external}</>;
                        }
                      })}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "MATHEMATICS") {
                          return <>{i.total}</>;
                        }
                      })}
                    </td>
                    <td>{GetGrade1to5(GetMark("MATHEMATICS").total)}</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>ENVIRONMENTAL STUDIES</td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "ENVIRONMENTAL STUDIES") {
                          return <>{i.internal}</>;
                        }
                      })}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "ENVIRONMENTAL STUDIES") {
                          return <>{i.external}</>;
                        }
                      })}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "ENVIRONMENTAL STUDIES") {
                          return <>{i.total}</>;
                        }
                      })}
                    </td>
                    <td>
                      {GetGrade1to5(GetMark("ENVIRONMENTAL STUDIES").total)}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={Styles.leftColumn}
                      style={{ fontWeight: 700, color: "#000080" }}
                    >
                      GRAND TOTAL
                    </td>
                    <td
                      className={Styles.leftcenteredColumn}
                      style={{ fontWeight: 700 }}
                    >
                      {handleInternalTotal()}
                    </td>
                    <td
                      className={Styles.leftcenteredColumn}
                      style={{ fontWeight: 700 }}
                    >
                      {handleExternalTotal()}
                    </td>
                    <td
                      style={{ fontWeight: 700 }}
                      className={Styles.leftcenteredColumn}
                    >
                      {handleTotal()}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>VALUE EDUCATION</td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.elective_name === "VALUE EDUCATION") {
                          return <>{i.internal}</>;
                        }
                      })}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.elective_name === "VALUE EDUCATION") {
                          return <>{i.external}</>;
                        }
                      })}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.elective_name === "VALUE EDUCATION") {
                          return <>{i.total}</>;
                        }
                      })}
                    </td>
                    <td>{GetGrade1to5(GetMarks("VALUE EDUCATION").total)}</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>COMPUTER SCIENCE</td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "COMPUTER SCIENCE") {
                          return <>{i.internal}</>;
                        }
                      })}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "COMPUTER SCIENCE") {
                          return <>{i.external}</>;
                        }
                      })}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "COMPUTER SCIENCE") {
                          return <>{i.total}</>;
                        }
                      })}
                    </td>
                    <td>{GetGrade1to5(GetMark("COMPUTER SCIENCE").total)}</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>GENERAL KNOWLEDGE</td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "GENERAL KNOWLEDGE") {
                          return <>{i.internal}</>;
                        }
                      })}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "GENERAL KNOWLEDGE") {
                          return <>{i.external}</>;
                        }
                      })}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {completeData.map((i) => {
                        if (i.subject_name === "GENERAL KNOWLEDGE") {
                          return <>{i.total}</>;
                        }
                      })}
                    </td>
                    <td>{GetGrade1to5(GetMark("GENERAL KNOWLEDGE").total)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            ""
          )}
          {gradeCategory === "Grade6" ||
          gradeCategory === "Grade7" ||
          gradeCategory === "Grade8" ? (
            <div className={Styles.tableWrapper}>
              <Watermark gradeCategory={gradeCategory} />
              <table className={Styles.tableBox}>
                <tbody>
                  <tr className={Styles.tableHeader}>
                    <td className={Styles.leftColumn}>SUBJECT</td>
                    <td>MARKS OBTAINED (80)</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>ENGLISH</td>
                    <td>{marksList.map((i) => i.ENGLISH)}</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>HINDI</td>
                    <td>{marksList.map((i) => i.HINDI)}</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>KANNADA</td>
                    <td>{marksList.map((i) => i.KANNADA)}</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>MATHEMATICS</td>
                    <td>{marksList.map((i) => i.MATHEMATICS)}</td>
                  </tr>

                  {gradeCategory === "Grade6" ||
                  gradeCategory === "Grade7" ||
                  gradeCategory === "Grade8" ? (
                    <>
                      <tr>
                        <td className={Styles.leftColumn}>SCIENCE</td>
                        <td>{marksList.map((i) => i.SCIENCE)}</td>
                      </tr>
                      <tr>
                        <td className={Styles.leftColumn}>SOCIAL SCIENCE</td>
                        <td>{marksList.map((i) => i["SOCIAL SCIENCE"])}</td>
                      </tr>
                      <tr>
                        <td className={Styles.grandTotal}>GRAND TOTAL</td>
                        <td style={{ fontWeight: 700 }}>{handleTotal()}</td>
                      </tr>
                    </>
                  ) : (
                    ""
                  )}

                  {gradeCategory === "Grade6" ||
                  gradeCategory === "Grade7" ||
                  gradeCategory === "Grade8" ? (
                    <>
                      <tr>
                        <td className={Styles.leftColumn}>VALUE EDUCATION</td>
                        <td>{marksList.map((i) => i["VALUE EDUCATION"])}</td>
                      </tr>
                    </>
                  ) : (
                    ""
                  )}

                  {gradeCategory === "Grade6" || gradeCategory === "Grade7" ? (
                    <tr>
                      <td className={Styles.leftColumn}>COMPUTER SCIENCE</td>
                      <td>{marksList.map((i) => i["COMPUTER SCIENCE"])}</td>
                    </tr>
                  ) : (
                    <tr>
                      <td className={Styles.leftColumn}>SKILL SUBJECT</td>
                      <td>{marksList.map((i) => i["SKILL SUBJECT"])}</td>
                    </tr>
                  )}
                  <tr>
                    <td className={Styles.leftColumn}>GENERAL KNOWLEDGE</td>
                    <td>{marksList.map((i) => i["GENERAL KNOWLEDGE"])}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (gradeCategory === "Grade9" && term !== "anual") ||
            gradeCategory === "Grade10" ? (
            <div className={Styles.tableWrapper1}>
              <Watermark gradeCategory={gradeCategory} />
              <table className={Styles.tableBox}>
                <tbody>
                  <tr className={Styles.tableHeader}>
                    <td className={Styles.leftcenteredColumn}>SUB CODE</td>
                    <td className={Styles.leftColumn}>SUBJECT</td>
                    <td className={Styles.leftValues}>MARKS OBTAINED (80)</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftcenteredColumn}>
                      {subcodeList.map((i) => i["ENGLISH LAN & LIT."])}
                    </td>
                    <td className={Styles.leftColumn}>ENGLISH LAN & LIT.</td>
                    <td>{marksList.map((i) => i["ENGLISH LAN & LIT."])}</td>
                  </tr>
                  {/* <tr>
                                            <td className={Styles.leftcenteredColumn} >{subcodeList.map(i => i.KANNADA)}</td>
                                            <td className={Styles.leftColumn}>KANNADA</td>
                                            <td>{marksList.map(i => i.KANNADA)}</td>
                                        </tr> */}
                  <tr>
                    <td className={Styles.leftcenteredColumn}>
                      {subcodeList.map((i) => i.MATHEMATICS)}
                    </td>
                    <td className={Styles.leftColumn}>MATHEMATICS</td>
                    <td>{marksList.map((i) => i.MATHEMATICS)}</td>
                  </tr>
                  {marksList.filter((i) => i["KANNADA"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftcenteredColumn}>
                        {subcodeList.map((i) => i.KANNADA)}
                      </td>
                      <td className={Styles.leftColumn}>KANNADA</td>
                      <td>{marksList.map((i) => i.KANNADA)}</td>
                    </tr>
                  ) : (
                    ""
                  )}
                  {marksList.filter((i) => i["HINDI COURSE-B"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftcenteredColumn}>
                        {subcodeList.map((i) => i["HINDI COURSE-B"])}
                      </td>
                      <td className={Styles.leftColumn}>HINDI COURSE-B</td>
                      <td>{marksList.map((i) => i["HINDI COURSE-B"])}</td>
                    </tr>
                  ) : (
                    ""
                  )}
                  <tr>
                    <td className={Styles.leftcenteredColumn}>
                      {subcodeList.map((i) => i.SCIENCE)}
                    </td>
                    <td className={Styles.leftColumn}>SCIENCE</td>
                    <td>{marksList.map((i) => i.SCIENCE)}</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftcenteredColumn}>
                      {subcodeList.map((i) => i["SOCIAL SCIENCE"])}
                    </td>
                    <td className={Styles.leftColumn}>SOCIAL SCIENCE</td>
                    <td>{marksList.map((i) => i["SOCIAL SCIENCE"])}</td>
                  </tr>
                  {/* <tr>
                    <td className={Styles.leftcenteredColumn}>
                      {subcodeList.map((i) => i["INFORMATION TECHNOLOGY"])}
                    </td>
                    <td className={Styles.leftColumn}>
                      INFORMATION TECHNOLOGY
                    </td>
                    <td>{marksList.map((i) => i["INFORMATION TECHNOLOGY"])}</td>
                  </tr> */}

                  <tr>
                    <td className={Styles.grandTotal} colSpan={2}>
                      GRAND TOTAL
                    </td>
                    <td style={{ fontWeight: 700 }}>{handleTotal()}</td>
                  </tr>
                  {gradeCategory === "Grade9" || gradeCategory === "Grade10" ? (
                    <>
                      <tr>
                        <td className={Styles.spannedColumn} colSpan={2}>
                          VALUE EDUCATION
                        </td>
                        <td>{marksList.map((i) => i["VALUE EDUCATION"])}</td>
                      </tr>
                      <tr>
                        <td className={Styles.spannedColumn} colSpan={2}>
                          GENERAL KNOWLEDGE
                        </td>
                        <td>{marksList.map((i) => i["GENERAL KNOWLEDGE"])}</td>
                      </tr>
                      <tr>
                        <td className={Styles.spannedColumn} colSpan={2}>
                          SKILL SUBJECT
                        </td>
                        <td>{marksList.map((i) => i["SKILL SUBJECT"])}</td>
                      </tr>
                    </>
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
            </div>
          ) : ((gradeCategory.includes("Grade11") && term !== "anual") ||
              (gradeCategory.includes("Grade12") && term !== "anual")) &&
            gradeCategory.includes("Science") ? (
            <div className={Styles.tableWrapper2}>
              <Watermark gradeCategory={gradeCategory} />
              <table className={Styles.tableBox}>
                <tbody>
                  <tr className={Styles.tableHeader}>
                    <td className={Styles.leftColumn}></td>
                    <td className={Styles.leftColumn}></td>
                    <td className={Styles.spannedHeader} colSpan={3}>
                      MARKS OBTAINED
                    </td>
                  </tr>
                  <tr className={Styles.tableHeader2}>
                    <td className={Styles.topalignedColumn}>SUB CODE</td>
                    <td className={Styles.topalignedColumn}>
                      <div>SUBJECT</div>
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      THEORY (80/70)
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      INTERNAL ASSESSMENT / PRACTICAL (20/30)
                    </td>
                    <td>TOTAL MARKS (100)</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["ENGLISH CORE"])}
                    </td>
                    <td className={Styles.leftColumn}>ENGLISH CORE</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ENGLISH CORE"])
                        .map((j) => j["ENGLISH CORE"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ENGLISH CORE"])
                        .map((j) => j["ENGLISH CORE"][1])}
                    </td>
                    <td>
                      {marksList
                        .filter((i) => i["ENGLISH CORE"])
                        .map((j) => j["ENGLISH CORE"][2])}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["MATHEMATICS"])}
                    </td>
                    <td className={Styles.leftColumn}>MATHEMATICS</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["MATHEMATICS"])
                        .map((j) => j["MATHEMATICS"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["MATHEMATICS"])
                        .map((j) => j["MATHEMATICS"][1])}
                    </td>
                    <td>
                      {marksList
                        .filter((i) => i["MATHEMATICS"])
                        .map((j) => j["MATHEMATICS"][2])}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["PHYSICS"])}
                    </td>
                    <td className={Styles.leftColumn}>PHYSICS</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["PHYSICS"])
                        .map((j) => j["PHYSICS"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["PHYSICS"])
                        .map((j) => j["PHYSICS"][1])}
                    </td>
                    <td>
                      {marksList
                        .filter((i) => i["PHYSICS"])
                        .map((j) => j["PHYSICS"][2])}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["CHEMISTRY"])}
                    </td>
                    <td className={Styles.leftColumn}>CHEMISTRY</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["CHEMISTRY"])
                        .map((j) => j["CHEMISTRY"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["CHEMISTRY"])
                        .map((j) => j["CHEMISTRY"][1])}
                    </td>
                    <td>
                      {marksList
                        .filter((i) => i["CHEMISTRY"])
                        .map((j) => j["CHEMISTRY"][2])}
                    </td>
                  </tr>
                  {marksList.filter((i) => i["COMPUTER SCIENCE"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftColumn}>
                        {subcodeList.map((i) => i["COMPUTER SCIENCE"])}
                      </td>
                      <td className={Styles.leftColumn}>COMPUTER SCIENCE</td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["COMPUTER SCIENCE"])
                          .map((j) => j["COMPUTER SCIENCE"][0])}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["COMPUTER SCIENCE"])
                          .map((j) => j["COMPUTER SCIENCE"][1])}
                      </td>
                      <td>
                        {marksList
                          .filter((i) => i["COMPUTER SCIENCE"])
                          .map((j) => j["COMPUTER SCIENCE"][2])}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                  {marksList.filter((i) => i["BIOLOGY"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftColumn}>
                        {subcodeList.map((i) => i["BIOLOGY"])}
                      </td>
                      <td className={Styles.leftColumn}>BIOLOGY</td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["BIOLOGY"])
                          .map((j) => j["BIOLOGY"][0])}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["BIOLOGY"])
                          .map((j) => j["BIOLOGY"][1])}
                      </td>
                      <td>
                        {marksList
                          .filter((i) => i["BIOLOGY"])
                          .map((j) => j["BIOLOGY"][2])}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}

                  <tr>
                    <td className={Styles.grandTotal} colSpan={2}>
                      GRAND TOTAL
                    </td>
                    <td className={Styles.grandTotal1}>{handleTotal(0)}</td>
                    <td className={Styles.grandTotal1}>{handleTotal(1)}</td>
                    <td style={{ fontWeight: 700 }}>{handleTotal(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : ((gradeCategory.includes("Grade11") && term !== "anual") ||
              (gradeCategory.includes("Grade12") && term !== "anual")) &&
            gradeCategory.includes("Commerce") ? (
            <div className={Styles.tableWrapper2}>
              <Watermark gradeCategory={gradeCategory} />
              <table className={Styles.tableBox}>
                <tbody>
                  <tr className={Styles.tableHeader}>
                    <td className={Styles.leftColumn}></td>
                    <td className={Styles.leftColumn}></td>
                    <td className={Styles.spannedHeader} colSpan={3}>
                      MARKS OBTAINED
                    </td>
                  </tr>
                  <tr className={Styles.tableHeader2}>
                    <td className={Styles.topalignedColumn}>SUB CODE</td>
                    <td className={Styles.topalignedColumn}>
                      <div>SUBJECT</div>
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      THEORY (80/70)
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      INTERNAL ASSESSMENT / PRACTICAL (20/30)
                    </td>
                    <td>TOTAL MARKS (100)</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["ENGLISH CORE"])}
                    </td>
                    <td className={Styles.leftColumn}>ENGLISH CORE</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ENGLISH CORE"])
                        .map((j) => j["ENGLISH CORE"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ENGLISH CORE"])
                        .map((j) => j["ENGLISH CORE"][1])}
                    </td>
                    <td>
                      {marksList
                        .filter((i) => i["ENGLISH CORE"])
                        .map((j) => j["ENGLISH CORE"][2])}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["ECONOMICS"])}
                    </td>
                    <td className={Styles.leftColumn}>ECONOMICS</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ECONOMICS"])
                        .map((j) => j["ECONOMICS"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ECONOMICS"])
                        .map((j) => j["ECONOMICS"][1])}
                    </td>
                    <td>
                      {marksList
                        .filter((i) => i["ECONOMICS"])
                        .map((j) => j["ECONOMICS"][2])}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["BUSINESS STUDIES"])}
                    </td>
                    <td className={Styles.leftColumn}>BUSINESS STUDIES</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["BUSINESS STUDIES"])
                        .map((j) => j["BUSINESS STUDIES"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["BUSINESS STUDIES"])
                        .map((j) => j["BUSINESS STUDIES"][1])}
                    </td>
                    <td>
                      {marksList
                        .filter((i) => i["BUSINESS STUDIES"])
                        .map((j) => j["BUSINESS STUDIES"][2])}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["ACCOUNTANCY"])}
                    </td>
                    <td className={Styles.leftColumn}>ACCOUNTANCY</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ACCOUNTANCY"])
                        .map((j) => j["ACCOUNTANCY"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ACCOUNTANCY"])
                        .map((j) => j["ACCOUNTANCY"][1])}
                    </td>
                    <td>
                      {marksList
                        .filter((i) => i["ACCOUNTANCY"])
                        .map((j) => j["ACCOUNTANCY"][2])}
                    </td>
                  </tr>
                  {marksList.filter((i) => i["COMPUTER SCIENCE"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftColumn}>
                        {subcodeList.map((i) => i["COMPUTER SCIENCE"])}
                      </td>
                      <td className={Styles.leftColumn}>COMPUTER SCIENCE</td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["COMPUTER SCIENCE"])
                          .map((j) => j["COMPUTER SCIENCE"][0])}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["COMPUTER SCIENCE"])
                          .map((j) => j["COMPUTER SCIENCE"][1])}
                      </td>
                      <td>
                        {marksList
                          .filter((i) => i["COMPUTER SCIENCE"])
                          .map((j) => j["COMPUTER SCIENCE"][2])}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                  {marksList.filter((i) => i["MATHEMATICS"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftColumn}>
                        {subcodeList.map((i) => i["MATHEMATICS"])}
                      </td>
                      <td className={Styles.leftColumn}>MATHEMATICS</td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["MATHEMATICS"])
                          .map((j) => j["MATHEMATICS"][0])}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["MATHEMATICS"])
                          .map((j) => j["MATHEMATICS"][1])}
                      </td>
                      <td>
                        {marksList
                          .filter((i) => i["MATHEMATICS"])
                          .map((j) => j["MATHEMATICS"][2])}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                  <tr>
                    <td className={Styles.grandTotal} colSpan={2}>
                      GRAND TOTAL
                    </td>
                    <td className={Styles.grandTotal1}>{handleTotal(0)}</td>
                    <td className={Styles.grandTotal1}>{handleTotal(1)}</td>
                    <td style={{ fontWeight: 700 }}>{handleTotal(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : gradeCategory.includes("Grade12") &&
            term === "anual" &&
            gradeCategory.includes("Commerce") ? (
            <div className={Styles.tableWrapper2}>
              <Watermark gradeCategory={gradeCategory} />
              <table className={Styles.tableBox}>
                <tbody>
                  <tr className={Styles.tableHeader2}>
                    <td className={Styles.leftcenteredColumn}>SUB CODE</td>
                    <td className={Styles.leftColumn}>SUBJECT</td>
                    <td className={Styles.leftValues}>
                      MARKS OBTAINED (80/70)
                    </td>
                  </tr>

                  <tr>
                    <td className={Styles.leftcenteredColumn}>
                      {subcodeList.map((i) => i["ENGLISH CORE"])}
                    </td>
                    <td className={Styles.leftColumn}>ENGLISH CORE</td>
                    {/* <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['ENGLISH CORE']).map(j => j['ENGLISH CORE'][0])}</td>
                                            <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['ENGLISH CORE']).map(j => j['ENGLISH CORE'][1])}</td> */}
                    <td>
                      {marksList
                        .filter((i) => i["ENGLISH CORE"])
                        .map((j) => j["ENGLISH CORE"][2])}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftcenteredColumn}>
                      {subcodeList.map((i) => i["ECONOMICS"])}
                    </td>
                    <td className={Styles.leftColumn}>ECONOMICS</td>
                    {/* <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['ECONOMICS']).map(j => j['ECONOMICS'][0])}</td>
                                            <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['ECONOMICS']).map(j => j['ECONOMICS'][1])}</td> */}
                    <td>
                      {marksList
                        .filter((i) => i["ECONOMICS"])
                        .map((j) => j["ECONOMICS"][2])}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftcenteredColumn}>
                      {subcodeList.map((i) => i["BUSINESS STUDIES"])}
                    </td>
                    <td className={Styles.leftColumn}>BUSINESS STUDIES</td>
                    {/* <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['BUSINESS STUDIES']).map(j => j['BUSINESS STUDIES'][0])}</td>
                                            <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['BUSINESS STUDIES']).map(j => j['BUSINESS STUDIES'][1])}</td> */}
                    <td>
                      {marksList
                        .filter((i) => i["BUSINESS STUDIES"])
                        .map((j) => j["BUSINESS STUDIES"][2])}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftcenteredColumn}>
                      {subcodeList.map((i) => i["ACCOUNTANCY"])}
                    </td>
                    <td className={Styles.leftColumn}>ACCOUNTANCY</td>
                    {/* <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['ACCOUNTANCY']).map(j => j['ACCOUNTANCY'][0])}</td>
                                            <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['ACCOUNTANCY']).map(j => j['ACCOUNTANCY'][1])}</td> */}
                    <td>
                      {marksList
                        .filter((i) => i["ACCOUNTANCY"])
                        .map((j) => j["ACCOUNTANCY"][2])}
                    </td>
                  </tr>
                  {marksList.filter((i) => i["COMPUTER SCIENCE"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftcenteredColumn}>
                        {subcodeList.map((i) => i["COMPUTER SCIENCE"])}
                      </td>
                      <td className={Styles.leftColumn}>COMPUTER SCIENCE</td>
                      {/* <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['COMPUTER SCIENCE']).map(j => j['COMPUTER SCIENCE'][0])}</td>
                                                <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['COMPUTER SCIENCE']).map(j => j['COMPUTER SCIENCE'][1])}</td> */}
                      <td>
                        {marksList
                          .filter((i) => i["COMPUTER SCIENCE"])
                          .map((j) => j["COMPUTER SCIENCE"][2])}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                  {marksList.filter((i) => i["MATHEMATICS"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftcenteredColumn}>
                        {subcodeList.map((i) => i["MATHEMATICS"])}
                      </td>
                      <td className={Styles.leftColumn}>MATHEMATICS</td>
                      {/* <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['MATHEMATICS']).map(j => j['MATHEMATICS'][0])}</td>
                                                <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['MATHEMATICS']).map(j => j['MATHEMATICS'][1])}</td> */}
                      <td>
                        {marksList
                          .filter((i) => i["MATHEMATICS"])
                          .map((j) => j["MATHEMATICS"][2])}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                  <tr>
                    <td className={Styles.grandTotal} colSpan={2}>
                      GRAND TOTAL
                    </td>
                    {/* <td className={Styles.grandTotal1}>{handleTotal(0)}</td>
                                            <td className={Styles.grandTotal1}>{handleTotal(1)}</td> */}
                    <td style={{ fontWeight: 700 }}>{handleTotal(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : gradeCategory.includes("Grade12") &&
            term === "anual" &&
            gradeCategory.includes("Science") ? (
            <div className={Styles.tableWrapper2}>
              <Watermark gradeCategory={gradeCategory} />
              <table className={Styles.tableBox}>
                <tbody>
                  <tr className={Styles.tableHeader2}>
                    <td className={Styles.leftcenteredColumn}>SUB CODE</td>
                    <td className={Styles.leftColumn}>
                      <div>SUBJECT</div>
                    </td>
                    <td className={Styles.leftValues}>
                      MARKS OBTAINED (80/70)
                    </td>
                  </tr>
                  {/* <tr className={Styles.tableHeader2}>
                                            <td className={Styles.topalignedColumn}>SUB CODE</td>
                                            <td className={Styles.topalignedColumn}><div>SUBJECT</div></td>
                                            <td className={Styles.leftcenteredColumn} >THEORY (80/70)</td>
                                            <td className={Styles.leftcenteredColumn} >INTERNAL ASSESSMENT / PRACTICAL (20/30)</td>
                                            <td>TOTAL MARKS (100)</td>
                                        </tr> */}
                  <tr>
                    <td className={Styles.leftcenteredColumn}>
                      {subcodeList.map((i) => i["ENGLISH CORE"])}
                    </td>
                    <td className={Styles.leftColumn}>ENGLISH CORE</td>
                    {/* <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['ENGLISH CORE']).map(j => j['ENGLISH CORE'][0])}</td>
                                            <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['ENGLISH CORE']).map(j => j['ENGLISH CORE'][1])}</td> */}
                    <td>
                      {marksList
                        .filter((i) => i["ENGLISH CORE"])
                        .map((j) => j["ENGLISH CORE"][2])}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftcenteredColumn}>
                      {subcodeList.map((i) => i["MATHEMATICS"])}
                    </td>
                    <td className={Styles.leftColumn}>MATHEMATICS</td>
                    {/* <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['MATHEMATICS']).map(j => j['MATHEMATICS'][0])}</td>
                                            <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['MATHEMATICS']).map(j => j['MATHEMATICS'][1])}</td> */}
                    <td>
                      {marksList
                        .filter((i) => i["MATHEMATICS"])
                        .map((j) => j["MATHEMATICS"][2])}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftcenteredColumn}>
                      {subcodeList.map((i) => i["PHYSICS"])}
                    </td>
                    <td className={Styles.leftColumn}>PHYSICS</td>
                    {/* <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['PHYSICS']).map(j => j['PHYSICS'][0])}</td>
                                            <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['PHYSICS']).map(j => j['PHYSICS'][1])}</td> */}
                    <td>
                      {marksList
                        .filter((i) => i["PHYSICS"])
                        .map((j) => j["PHYSICS"][2])}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftcenteredColumn}>
                      {subcodeList.map((i) => i["CHEMISTRY"])}
                    </td>
                    <td className={Styles.leftColumn}>CHEMISTRY</td>
                    {/* <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['CHEMISTRY']).map(j => j['CHEMISTRY'][0])}</td>
                                            <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['CHEMISTRY']).map(j => j['CHEMISTRY'][1])}</td> */}
                    <td>
                      {marksList
                        .filter((i) => i["CHEMISTRY"])
                        .map((j) => j["CHEMISTRY"][2])}
                    </td>
                  </tr>
                  {marksList.filter((i) => i["COMPUTER SCIENCE"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftcenteredColumn}>
                        {subcodeList.map((i) => i["COMPUTER SCIENCE"])}
                      </td>
                      <td className={Styles.leftColumn}>COMPUTER SCIENCE</td>
                      {/* <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['COMPUTER SCIENCE']).map(j => j['COMPUTER SCIENCE'][0])}</td>
                                                <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['COMPUTER SCIENCE']).map(j => j['COMPUTER SCIENCE'][1])}</td> */}
                      <td>
                        {marksList
                          .filter((i) => i["COMPUTER SCIENCE"])
                          .map((j) => j["COMPUTER SCIENCE"][2])}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                  {marksList.filter((i) => i["BIOLOGY"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftcenteredColumn}>
                        {subcodeList.map((i) => i["BIOLOGY"])}
                      </td>
                      <td className={Styles.leftColumn}>BIOLOGY</td>
                      {/* <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['BIOLOGY']).map(j => j['BIOLOGY'][0])}</td>
                                                <td className={Styles.leftcenteredColumn}>{marksList.filter(i => i['BIOLOGY']).map(j => j['BIOLOGY'][1])}</td> */}
                      <td>
                        {marksList
                          .filter((i) => i["BIOLOGY"])
                          .map((j) => j["BIOLOGY"][2])}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}

                  <tr>
                    <td className={Styles.grandTotal} colSpan={2}>
                      GRAND TOTAL
                    </td>
                    {/* <td className={Styles.grandTotal1}>{handleTotal(0)}</td>
                                            <td className={Styles.grandTotal1}>{handleTotal(1)}</td> */}
                    <td style={{ fontWeight: 700 }}>{handleTotal(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : gradeCategory.includes("Grade11") &&
            term === "anual" &&
            gradeCategory.includes("Science") ? (
            <div className={Styles.tableWrapper2}>
              <Watermark gradeCategory={gradeCategory} />
              <table className={Styles.tableBox}>
                <tbody>
                  <tr className={Styles.tableHeader}>
                    <td className={Styles.leftColumn}></td>
                    <td className={Styles.leftColumn}></td>
                    <td className={Styles.spannedHeader} colSpan={4}>
                      MARKS OBTAINED
                    </td>
                  </tr>
                  <tr className={Styles.tableHeader2}>
                    <td className={Styles.topalignedColumn}>SUB CODE</td>
                    <td className={Styles.topalignedColumn}>
                      <div>SUBJECT</div>
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      INTERNAL ASSESSMENT / PRACTICAL (20/30)
                    </td>
                    <td
                      style={{ "text-align": "center" }}
                      className={Styles.leftcenteredColumn}
                    >
                      THEORY(80 / 70 )
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      TOTAL MARKS (100)
                    </td>
                    <td>GRADE</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["ENGLISH CORE"])}
                    </td>
                    <td className={Styles.leftColumn}>ENGLISH CORE</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ENGLISH CORE"])
                        .map((j) => j["ENGLISH CORE"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ENGLISH CORE"])
                        .map((j) => j["ENGLISH CORE"][1])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ENGLISH CORE"])
                        .map((j) => j["ENGLISH CORE"][2])}
                    </td>
                    <td>
                      {GetGrade(
                        marksList
                          .filter((i) => i["ENGLISH CORE"])
                          .map((j) => j["ENGLISH CORE"][2])
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["MATHEMATICS"])}
                    </td>
                    <td className={Styles.leftColumn}>MATHEMATICS</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["MATHEMATICS"])
                        .map((j) => j["MATHEMATICS"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["MATHEMATICS"])
                        .map((j) => j["MATHEMATICS"][1])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["MATHEMATICS"])
                        .map((j) => j["MATHEMATICS"][2])}
                    </td>
                    <td>
                      {GetGrade(
                        marksList
                          .filter((i) => i["MATHEMATICS"])
                          .map((j) => j["MATHEMATICS"][2])
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["PHYSICS"])}
                    </td>
                    <td className={Styles.leftColumn}>PHYSICS</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["PHYSICS"])
                        .map((j) => j["PHYSICS"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["PHYSICS"])
                        .map((j) => j["PHYSICS"][1])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["PHYSICS"])
                        .map((j) => j["PHYSICS"][2])}
                    </td>
                    <td>
                      {GetGrade(
                        marksList
                          .filter((i) => i["PHYSICS"])
                          .map((j) => j["PHYSICS"][2])
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["CHEMISTRY"])}
                    </td>
                    <td className={Styles.leftColumn}>CHEMISTRY</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["CHEMISTRY"])
                        .map((j) => j["CHEMISTRY"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["CHEMISTRY"])
                        .map((j) => j["CHEMISTRY"][1])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["CHEMISTRY"])
                        .map((j) => j["CHEMISTRY"][2])}
                    </td>
                    <td>
                      {GetGrade(
                        marksList
                          .filter((i) => i["CHEMISTRY"])
                          .map((j) => j["CHEMISTRY"][2])
                      )}
                    </td>
                  </tr>
                  {marksList.filter((i) => i["COMPUTER SCIENCE"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftColumn}>
                        {subcodeList.map((i) => i["COMPUTER SCIENCE"])}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        COMPUTER SCIENCE
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["COMPUTER SCIENCE"])
                          .map((j) => j["COMPUTER SCIENCE"][0])}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["COMPUTER SCIENCE"])
                          .map((j) => j["COMPUTER SCIENCE"][1])}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["COMPUTER SCIENCE"])
                          .map((j) => j["COMPUTER SCIENCE"][2])}
                      </td>
                      <td>
                        {GetGrade(
                          marksList
                            .filter((i) => i["COMPUTER SCIENCE"])
                            .map((j) => j["COMPUTER SCIENCE"][2])
                        )}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                  {marksList.filter((i) => i["BIOLOGY"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftColumn}>
                        {subcodeList.map((i) => i["BIOLOGY"])}
                      </td>
                      <td className={Styles.leftColumn}>BIOLOGY</td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["BIOLOGY"])
                          .map((j) => j["BIOLOGY"][0])}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["BIOLOGY"])
                          .map((j) => j["BIOLOGY"][1])}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["BIOLOGY"])
                          .map((j) => j["BIOLOGY"][2])}
                      </td>
                      <td>
                        {GetGrade(
                          marksList
                            .filter((i) => i["BIOLOGY"])
                            .map((j) => j["BIOLOGY"][2])
                        )}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}

                  <tr>
                    <td className={Styles.grandTotal} colSpan={2}>
                      GRAND TOTAL
                    </td>
                    <td className={Styles.grandTotal1}>{handleTotal(0)}</td>
                    <td className={Styles.grandTotal1}>{handleTotal(1)}</td>
                    <td
                      className={Styles.leftcenteredColumn}
                      style={{ fontWeight: 700 }}
                    >
                      {handleTotal(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : gradeCategory.includes("Grade11") &&
            term === "anual" &&
            gradeCategory.includes("Commerce") ? (
            <div className={Styles.tableWrapper2}>
              <Watermark gradeCategory={gradeCategory} />
              <table className={Styles.tableBox}>
                <tbody>
                  <tr className={Styles.tableHeader}>
                    <td className={Styles.leftColumn}></td>
                    <td className={Styles.leftColumn}></td>
                    <td className={Styles.spannedHeader} colSpan={4}>
                      MARKS OBTAINED
                    </td>
                  </tr>
                  <tr className={Styles.tableHeader2}>
                    <td className={Styles.topalignedColumn}>SUB CODE</td>
                    <td className={Styles.topalignedColumn}>
                      <div>SUBJECT</div>
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      INTERNAL ASSESSMENT / PRACTICAL (20/30)
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      THEORY ( 80 / 70 )
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      TOTAL MARKS (100)
                    </td>
                    <td>Grade</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["ENGLISH CORE"])}
                    </td>
                    <td className={Styles.leftColumn}>ENGLISH CORE</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ENGLISH CORE"])
                        .map((j) => j["ENGLISH CORE"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ENGLISH CORE"])
                        .map((j) => j["ENGLISH CORE"][1])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ENGLISH CORE"])
                        .map((j) => j["ENGLISH CORE"][2])}
                    </td>
                    <td>
                      {GetGrade(
                        marksList
                          .filter((i) => i["ENGLISH CORE"])
                          .map((j) => j["ENGLISH CORE"][2])
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["ECONOMICS"])}
                    </td>
                    <td className={Styles.leftColumn}>ECONOMICS</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ECONOMICS"])
                        .map((j) => j["ECONOMICS"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ECONOMICS"])
                        .map((j) => j["ECONOMICS"][1])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ECONOMICS"])
                        .map((j) => j["ECONOMICS"][2])}
                    </td>
                    <td>
                      {GetGrade(
                        marksList
                          .filter((i) => i["ECONOMICS"])
                          .map((j) => j["ECONOMICS"][2])
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["BUSINESS STUDIES"])}
                    </td>
                    <td className={Styles.leftColumn}>BUSINESS STUDIES</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["BUSINESS STUDIES"])
                        .map((j) => j["BUSINESS STUDIES"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["BUSINESS STUDIES"])
                        .map((j) => j["BUSINESS STUDIES"][1])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["BUSINESS STUDIES"])
                        .map((j) => j["BUSINESS STUDIES"][2])}
                    </td>
                    <td>
                      {GetGrade(
                        marksList
                          .filter((i) => i["BUSINESS STUDIES"])
                          .map((j) => j["BUSINESS STUDIES"][2])
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["ACCOUNTANCY"])}
                    </td>
                    <td className={Styles.leftColumn}>ACCOUNTANCY</td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ACCOUNTANCY"])
                        .map((j) => j["ACCOUNTANCY"][0])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ACCOUNTANCY"])
                        .map((j) => j["ACCOUNTANCY"][1])}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {marksList
                        .filter((i) => i["ACCOUNTANCY"])
                        .map((j) => j["ACCOUNTANCY"][2])}
                    </td>
                    <td>
                      {GetGrade(
                        marksList
                          .filter((i) => i["ACCOUNTANCY"])
                          .map((j) => j["ACCOUNTANCY"][2])
                      )}
                    </td>
                  </tr>
                  {marksList.filter((i) => i["COMPUTER SCIENCE"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftColumn}>
                        {subcodeList.map((i) => i["COMPUTER SCIENCE"])}
                      </td>
                      <td className={Styles.leftColumn}>COMPUTER SCIENCE</td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["COMPUTER SCIENCE"])
                          .map((j) => j["COMPUTER SCIENCE"][0])}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["COMPUTER SCIENCE"])
                          .map((j) => j["COMPUTER SCIENCE"][1])}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["COMPUTER SCIENCE"])
                          .map((j) => j["COMPUTER SCIENCE"][2])}
                      </td>
                      <td>
                        {GetGrade(
                          marksList
                            .filter((i) => i["COMPUTER SCIENCE"])
                            .map((j) => j["COMPUTER SCIENCE"][2])
                        )}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                  {marksList.filter((i) => i["MATHEMATICS"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftColumn}>
                        {subcodeList.map((i) => i["MATHEMATICS"])}
                      </td>
                      <td className={Styles.leftColumn}>MATHEMATICS</td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["MATHEMATICS"])
                          .map((j) => j["MATHEMATICS"][0])}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["MATHEMATICS"])
                          .map((j) => j["MATHEMATICS"][1])}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {marksList
                          .filter((i) => i["MATHEMATICS"])
                          .map((j) => j["MATHEMATICS"][2])}
                      </td>
                      <td>
                        {GetGrade(
                          marksList
                            .filter((i) => i["MATHEMATICS"])
                            .map((j) => j["MATHEMATICS"][2])
                        )}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                  <tr>
                    <td className={Styles.grandTotal} colSpan={2}>
                      GRAND TOTAL
                    </td>
                    <td className={Styles.grandTotal1}>
                      {handleTotalNewfor11("internal")}
                    </td>
                    <td className={Styles.grandTotal1}>
                      {handleTotalNewfor11("external")}
                    </td>
                    <td
                      className={Styles.leftcenteredColumn}
                      style={{ fontWeight: 700 }}
                    >
                      {handleTotalNewfor11("total")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : gradeCategory === "Grade9" && term === "anual" ? (
            <div
              style={{ "margin-bottom": "90px" }}
              className={Styles.tableWrapper2}
            >
              <Watermark gradeCategory={gradeCategory} />
              <table className={Styles.tableBox}>
                <tbody>
                  <tr className={Styles.tableHeader}>
                    <td className={Styles.leftColumn}></td>
                    <td className={Styles.leftColumn}></td>
                    <td className={Styles.spannedHeader} colSpan={4}>
                      MARKS OBTAINED
                    </td>
                  </tr>
                  <tr className={Styles.tableHeader2}>
                    <td className={Styles.topalignedColumn}>SUB CODE</td>
                    <td className={Styles.topalignedColumn}>
                      <div>SUBJECT</div>
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      INTERNAL ASSESSMENT ( 20 )
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      FINAL EXAM ( 80 )
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      TOTAL MARKS (100)
                    </td>
                    <td>Grade</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["ENGLISH LAN & LIT."])}
                    </td>
                    <td className={Styles.leftColumn}>ENGLISH LAN & LIT.</td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("ENGLISH LAN & LIT.").internal}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("ENGLISH LAN & LIT.").external}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("ENGLISH LAN & LIT.").total}
                    </td>
                    <td>{GetGrade(GetMark("ENGLISH LAN & LIT.").total)}</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["MATHEMATICS"])}
                    </td>
                    <td className={Styles.leftColumn}>MATHEMATICS</td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("MATHEMATICS").internal}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("MATHEMATICS").external}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("MATHEMATICS").total}
                    </td>
                    <td>{GetGrade(GetMark("MATHEMATICS").total)}</td>
                  </tr>

                  {marksList.filter((i) => i["KANNADA"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftColumn}>
                        {subcodeList.map((i) => i["KANNADA"])}
                      </td>
                      <td className={Styles.leftColumn}>KANNADA</td>
                      <td className={Styles.leftcenteredColumn}>
                        {GetMark("KANNADA").internal}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {GetMark("KANNADA").external}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {GetMark("KANNADA").total}
                      </td>
                      <td>{GetGrade(GetMark("KANNADA").total)}</td>
                    </tr>
                  ) : (
                    ""
                  )}
                  {marksList.filter((i) => i["HINDI COURSE-B"]).length > 0 ? (
                    <tr>
                      <td className={Styles.leftColumn}>
                        {subcodeList.map((i) => i["HINDI COURSE-B"])}
                      </td>
                      <td className={Styles.leftColumn}>HINDI COURSE-B</td>
                      <td className={Styles.leftcenteredColumn}>
                        {GetMark("HINDI COURSE-B").internal}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {GetMark("HINDI COURSE-B").external}
                      </td>
                      <td className={Styles.leftcenteredColumn}>
                        {GetMark("HINDI COURSE-B").total}
                      </td>
                      <td>{GetGrade(GetMark("HINDI COURSE-B").total)}</td>
                    </tr>
                  ) : (
                    ""
                  )}
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["SCIENCE"])}
                    </td>
                    <td className={Styles.leftColumn}>SCIENCE</td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("SCIENCE").internal}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("SCIENCE").external}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("SCIENCE").total}
                    </td>
                    <td>{GetGrade(GetMark("SCIENCE").total)}</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["SOCIAL SCIENCE"])}
                    </td>
                    <td className={Styles.leftColumn}>SOCIAL SCIENCE</td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("SOCIAL SCIENCE").internal}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("SOCIAL SCIENCE").external}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("SOCIAL SCIENCE").total}
                    </td>
                    <td>{GetGrade(GetMark("SOCIAL SCIENCE").total)}</td>
                  </tr>
                  <tr>
                    <td className={Styles.leftColumn}>
                      {subcodeList.map((i) => i["INFORMATION TECHNOLOGY"])}
                    </td>
                    <td className={Styles.leftColumn}>
                      INFORMATION TECHNOLOGY
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("INFORMATION TECHNOLOGY").internal}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("INFORMATION TECHNOLOGY").external}
                    </td>
                    <td className={Styles.leftcenteredColumn}>
                      {GetMark("INFORMATION TECHNOLOGY").total}
                    </td>
                    <td>{GetGrade(GetMark("INFORMATION TECHNOLOGY").total)}</td>
                  </tr>
                  <tr>
                    <td className={Styles.grandTotal} colSpan={2}>
                      GRAND TOTAL
                    </td>
                    <td className={Styles.grandTotal1}>
                      {handleTotalNew("internal")}
                    </td>
                    <td className={Styles.grandTotal1}>
                      {handleTotalNew("external")}
                    </td>
                    <td
                      className={Styles.leftcenteredColumn}
                      style={{ fontWeight: 700 }}
                    >
                      {handleTotalNew("total")}
                    </td>
                  </tr>
                  {gradeCategory === "Grade9" ? (
                    <>
                      <tr>
                        <td className={Styles.spannedColumn} colSpan={2}>
                          VALUE EDUCATION
                        </td>
                        <td className={Styles.leftcenteredColumn}>
                          {GetMark("VALUE EDUCATION")?.internal}
                        </td>
                        <td className={Styles.leftcenteredColumn}>
                          {GetMark("VALUE EDUCATION")?.external}
                        </td>

                        <td className={Styles.leftcenteredColumn}>
                          {GetMark("VALUE EDUCATION")?.total}
                        </td>
                        <td>{GetGrade(GetMark("VALUE EDUCATION")?.total)}</td>
                      </tr>
                      <tr>
                        <td className={Styles.spannedColumn} colSpan={2}>
                          GENERAL KNOWLEDGE
                        </td>
                        <td className={Styles.leftcenteredColumn}>
                          {GetMark("GENERAL KNOWLEDGE")?.internal}
                        </td>
                        <td className={Styles.leftcenteredColumn}>
                          {GetMark("GENERAL KNOWLEDGE")?.external}
                        </td>
                        <td className={Styles.leftcenteredColumn}>
                          {GetMark("GENERAL KNOWLEDGE")?.total}
                        </td>
                        <td>{GetGrade(GetMark("GENERAL KNOWLEDGE")?.total)}</td>
                      </tr>
                    </>
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
};
export default ScholasticRecord;
