//App.js
import "./App.css";
// import "../src/pages/AdminDashboard/ReportCentre/ReportCentre.module.css";
import RouterConfig from "./routes/router";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useEffect } from "react";

function App() {
  const checkThemeMode = () => {
    const value = localStorage.getItem("theme");
    if (value == "light") {
      document.getElementById("root").classList.remove("dark");
      document.getElementById("root").classList.add("light");
    } else if (value == "dark") {
      document.getElementById("root").classList.remove("light");
      document.getElementById("root").classList.add("dark");
    }
  };
  useEffect(() => {
    checkThemeMode();
  }, []);
  return <RouterConfig />;
}

export default App;
