import React, { useEffect, useState } from "react";
import { firebasestorage } from "config/firebaseInitialize";
import {
  ref,
  getDownloadURL,
  uploadBytesResumable,
  uploadBytes,
} from "firebase/storage";
import { firebaseDB } from "config/firebaseInitialize";
import { collection, setDoc, addDoc, doc, getDocs } from "firebase/firestore";
const Branding = () => {
  const [imgUrl, setImgUrl] = useState(null);
  const [progresspercent, setProgresspercent] = useState(0);
  const [data, setData] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    const file = e.target[0]?.files[0];
    if (!file) return;
    const storageRef = ref(firebasestorage, `Images/Background/${file.name}`);
    const metadata = {
      contentType: "Background-image",
    };
    const uploadTask = uploadBytes(storageRef, file, metadata);

    //   uploadTask.on("state_changed",
    //     (snapshot) => {
    //       const progress =
    //         Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
    //       setProgresspercent(progress);
    //     },
    //     (error) => {
    //       alert(error);
    //     },
    //   );
  };
  const addGenderDetailstoFirebase = async () => {
    const data = {
      gender: "female",
    };
    console.log("data", data);
    const dbRef = await addDoc(
      collection(firebaseDB, "Student", "id", "Gender"),
      data
    );
    console.log("dbRef", dbRef, data);
    const dbRef2 = await addDoc(
      collection(firebaseDB, "Student", "id", "value"),
      data
    );
  };
  const getFirebaseData = async () => {
    const val = doc(firebaseDB, "Student", "id");
    const collectionVal = collection(val, "Gender");
    const getValue = await getDocs(collectionVal);
    setData(getValue.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };
  useEffect(() => {
    getFirebaseData();
  }, []);
  console.log("data", data && data);
  return (
    <div>
      <form onSubmit={handleSubmit} className="form">
        <input type="file" />
        <button type="submit">Upload</button>
      </form>
      {/* {
        !imgUrl &&
        <div className='outerbar'>
          <div className='innerbar' style={{ width: `${progresspercent}%` }}>{progresspercent}%</div>
        </div>
      } */}

      <div>
        <button onClick={() => addGenderDetailstoFirebase()}>send</button>
      </div>
    </div>
  );
};

export default Branding;
