import {
  Button,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Modal,
  ModalFooter,
  useToast,
  Tooltip,
  Select,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Styles from "../Library.module.scss";
import edit from "assets/icons/editIcon.png";
import deleteIcon from "assets/icons/deleteIcon.png";
import add from "assets/icons/addWhite.png";
import Services from "services/admin-dashboard/library.service";
import AddBook from "./AddBooks";
const Books = () => {
  const [allBooks, setAllBooks] = useState("");
  const [bookId, setBookId] = useState("");
  const [categories, setCategories] = useState("");
  const [addBook, setAddBook] = useState({ book_code_array: [] });
  const [selectCategory, setSelectCategory] = useState("");
  // const [bookId,setBookId] = useState("")
  const deleteBook = useDisclosure();
  const addBookModal = useDisclosure();
  const updateBookModal = useDisclosure();
  const toast = useToast();

  const [count, setCount] = useState(1); // useState returns a pair. 'count' is the current state. 'setCount' is a function we can use to update the state.
  const [addBookCode, setAddBookCode] = useState([]);
  function increment() {
    //setCount(prevCount => prevCount+=1);
    setCount(function (prevCount) {
      return (prevCount += 1);
    });
  }

  function decrement() {
    setCount(function (prevCount) {
      if (prevCount > 1) {
        return (prevCount -= 1);
      } else {
        return (prevCount = 1);
      }
    });
  }

  const requestBookByCategory = async () => {
    try {
      const payload = {
        category: selectCategory,
      };
      const res = await Services.requestBookByCategory(payload);
      if (res) {
        setAllBooks(res);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    requestBookByCategory();
  }, [selectCategory]);
  const handleDeleteBook = async () => {
    try {
    } catch (err) {
      console.log(err);
    }
  };
  const getCategories = async () => {
    try {
      const response = await Services.requestAllCategory();
      if (response) {
        setCategories(response);
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  useEffect(() => {
    getCategories();
  }, []);
  const addBookData = (e) => {
    setAddBook({ ...addBook, [e.target.name]: e.target.value });
  };
  const handleAddBook = async () => {
    try {
      if (
        addBook.book_title &&
        addBook.book_desc &&
        count &&
        addBook.author_name &&
        addBook.author_email &&
        addBook.author_desc &&
        addBook.book_category_id_array &&
        addBook.book_code_array
      ) {
        const payload = {
          book_title: addBook.book_title,
          book_desc: addBook.book_desc,
          copies: +count,
          author_details: {
            author_name: addBook.author_name,
            author_email: addBook.author_email,
            author_desc: addBook.author_desc,
          },
          book_category_id_array: [addBook.book_category_id_array],
          book_code_array: addBook.book_code_array,
        };
        const res = await Services.requestAddBook(payload);
        if (res) {
          toast({
            title: "Book ",
            description: "Book Created Successfully",
            status: "success",
            duration: 4000,
            isClosable: true,
            position: "top-right",
          });
          addBook.onClose();
        }
      } else {
        toast({
          title: "Please fill in all the required fields.",
          description: "All fields marked with an asterisk (*) are required.",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (err) {
      console.log("error", err);
      toast({
        title: "Request Failed",
        description: err.msg,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handleBookCodeChange = (e, index) => {
    const inputValue = e.target.value.trim();
    const newBookCodes = [...addBook.book_code_array];
    newBookCodes[index] = inputValue;
    setAddBook((prevBook) => ({
      ...prevBook,
      book_code_array: newBookCodes,
    }));
  };

  return (
    <>
      <div className={Styles.BooksBody}>
        <div className={Styles.header}>
          <div className={Styles.title}>
            <p>Books</p>
          </div>
          <div className={Styles.search}>
            <Input type="text" placeholder="Search" />
            <div
              onClick={() => {
                addBookModal.onOpen();
              }}
            >
              <Button colorScheme="teal">Create</Button>
            </div>
            <div>
              <Button colorScheme="green">Archive</Button>
            </div>
          </div>
        </div>
        <div className={Styles.mainSection}>
          <div className={Styles.SectionLeft}>
            <div>
              <label className={Styles.containerCate}>
                <input
                  type="radio"
                  name="radio"
                  onChange={() => setSelectCategory("")}
                  checked={selectCategory == "" ? true : false}
                />
                <span className={Styles.checkmarkCate}></span>
                All
              </label>
              {categories &&
                categories.map((category) => (
                  <>
                    <label className={Styles.containerCate}>
                      <input
                        type="radio"
                        name={category.category_name}
                        onChange={() =>
                          setSelectCategory(category.category_name)
                        }
                        checked={
                          category.category_name == selectCategory
                            ? true
                            : false
                        }
                      />
                      <span className={Styles.checkmarkCate}></span>
                      {category.category_name}
                    </label>
                  </>
                ))}
            </div>
          </div>
          <div className={Styles.SectionRight}>
            {allBooks &&
              allBooks.map((book) => (
                <>
                  <div className={Styles.SingleBook}>
                    <div>
                      <p className={Styles.bookName}>{book.book_title}</p>
                      <p>{book.book_desc} </p>
                    </div>
                    <div>
                      <div className={Styles.icons}>
                        <div
                          className={Styles.icon}
                          onClick={() => {
                            updateBookModal.onOpen();
                            setBookId();
                          }}
                        >
                          <img src={edit} alt="" />
                        </div>
                        <div
                          className={Styles.icon}
                          onClick={() => {
                            deleteBook.onOpen();
                            setBookId(book);
                          }}
                        >
                          <img src={deleteIcon} alt="" />
                        </div>
                      </div>
                      <div>copyies : {book.copies}</div>
                    </div>
                  </div>
                </>
              ))}
          </div>
        </div>
        <Modal isOpen={deleteBook.isOpen} onClose={deleteBook.onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Delete Category</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div>
                <p>Are you sure you want to delete this category ?</p>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="red"
                variant="solid"
                mr={3}
                onClick={() => {
                  handleDeleteBook();
                }}
              >
                Delete
              </Button>
              <Button colorScheme="blue" onClick={() => deleteBook.onClose()}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal isOpen={addBookModal.isOpen} onClose={addBookModal.onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add Book</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div
                style={{ height: "30vw", overflow: "auto" }}
                className={Styles.allField}
              >
                <div className={Styles.tagContanier}>
                  <p>
                    Book Title <span className={Styles.asterisk}>*</span> :
                  </p>
                  <div className={Styles.inputTags}>
                    <input
                      placeholder="Title"
                      type="text"
                      name="book_title"
                      onChange={(e) => addBookData(e)}
                      required
                    />
                  </div>
                </div>
                <div className={Styles.tagContanier}>
                  <p>
                    Book Description <span className={Styles.asterisk}>*</span>{" "}
                    :
                  </p>
                  <div className={Styles.inputTags}>
                    <input
                      placeholder="Description"
                      type="text"
                      name="book_desc"
                      onChange={(e) => addBookData(e)}
                      required
                    />
                  </div>
                </div>
                <div className={Styles.tagContanier}>
                  <p>
                    Book Categories <span className={Styles.asterisk}>*</span> :
                  </p>
                  <div className={Styles.inputTags}>
                    <Select
                      placeholder="Select Book Categories"
                      type="text"
                      name="book_category_id_array"
                      onChange={(e) => addBookData(e)}
                      required
                    >
                      {categories &&
                        categories.map((data) => (
                          <>
                            <option value={data._id}>
                              {data.category_name}
                            </option>
                          </>
                        ))}
                    </Select>
                  </div>
                </div>
                <div className={Styles.tagContanier}>
                  <p>
                    Book Author Name <span className={Styles.asterisk}>*</span>{" "}
                    :
                  </p>
                  <div className={Styles.inputTags}>
                    <input
                      placeholder="Author Name"
                      type="text"
                      name="author_name"
                      onChange={(e) => addBookData(e)}
                      required
                    />
                  </div>
                </div>
                <div className={Styles.tagContanier}>
                  <p>
                    Book Author Email <span className={Styles.asterisk}>*</span>{" "}
                    :
                  </p>
                  <div className={Styles.inputTags}>
                    <input
                      placeholder="Author Email"
                      type="email"
                      name="author_email"
                      onChange={(e) => addBookData(e)}
                      required
                    />
                  </div>
                </div>
                <div className={Styles.tagContanier}>
                  <p>
                    Book Author Description{" "}
                    <span className={Styles.asterisk}>*</span> :
                  </p>
                  <div className={Styles.inputTags}>
                    <input
                      placeholder="Author Description"
                      type="text"
                      name="author_desc"
                      onChange={(e) => addBookData(e)}
                      required
                    />
                  </div>
                </div>
                <div className={Styles.tagContanier}>
                  <p>
                    No. of Copies <span className={Styles.asterisk}>*</span> :
                  </p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className={Styles.inputTags}
                  >
                    <Button style={{ fontSize: "20px" }} onClick={decrement}>
                      -
                    </Button>
                    <h1 style={{ marginLeft: "10px", marginRight: "10px" }}>
                      {count}
                    </h1>
                    <Button style={{ fontSize: "20px" }} onClick={increment}>
                      +
                    </Button>
                  </div>
                </div>
                <div className={Styles.tagContanier}>
                  <p>
                    Book Code <span className={Styles.asterisk}>*</span> :
                  </p>
                  <div className={Styles.inputTags}>
                    {[...Array(count)].map((_, index) => (
                      <input
                        key={index}
                        placeholder="Book Code"
                        type="text"
                        name={`book_code_array[${index}]`}
                        onChange={(e) => handleBookCodeChange(e, index)}
                        required
                      />
                    ))}
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => handleAddBook()}
                style={{ background: "red", color: "white" }}
              >
                Create Book
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal
          isOpen={updateBookModal.isOpen}
          onClose={updateBookModal.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Update Book</ModalHeader>
            <ModalCloseButton />
            <ModalBody style={{ height: "24vw", overflow: "auto" }}>
              <div className={Styles.allField}>
                <div className={Styles.tagContanier}>
                  <p>
                    Book Title <span className={Styles.asterisk}>*</span> :
                  </p>
                  <div className={Styles.inputTags}>
                    <input
                      placeholder="Title"
                      type="text"
                      name="book_title"
                      onChange={(e) => addBookData(e)}
                      required
                    />
                  </div>
                </div>
                <div className={Styles.tagContanier}>
                  <p>
                    Book Description <span className={Styles.asterisk}>*</span>{" "}
                    :
                  </p>
                  <div className={Styles.inputTags}>
                    <input
                      placeholder="Description"
                      type="text"
                      name="book_desc"
                      onChange={(e) => addBookData(e)}
                      required
                    />
                  </div>
                </div>
                <div className={Styles.tagContanier}>
                  <p>
                    Book Categories <span className={Styles.asterisk}>*</span> :
                  </p>
                  <div className={Styles.inputTags}>
                    <Select
                      placeholder="Select Book Categories"
                      type="text"
                      name="book_category_id_array"
                      onChange={(e) => addBookData(e)}
                      required
                    >
                      {categories &&
                        categories.map((data) => (
                          <>
                            <option value={data._id}>
                              {data.category_name}
                            </option>
                          </>
                        ))}
                    </Select>
                  </div>
                </div>
                <div className={Styles.tagContanier}>
                  <p>
                    Book Author Name <span className={Styles.asterisk}>*</span>{" "}
                    :
                  </p>
                  <div className={Styles.inputTags}>
                    <input
                      placeholder="Author Name"
                      type="text"
                      name="author_name"
                      onChange={(e) => addBookData(e)}
                      required
                    />
                  </div>
                </div>
                <div className={Styles.tagContanier}>
                  <p>
                    Book Author Email <span className={Styles.asterisk}>*</span>{" "}
                    :
                  </p>
                  <div className={Styles.inputTags}>
                    {[...Array(count)].map((_, index) => (
                      <input
                        key={index}
                        placeholder="Book Code"
                        type="text"
                        name={`book_code_array[${index}]`}
                        onChange={(e) => handleBookCodeChange(e, index)}
                        required
                      />
                    ))}
                  </div>
                </div>
                <div className={Styles.tagContanier}>
                  <p>
                    Book Author Description{" "}
                    <span className={Styles.asterisk}>*</span> :
                  </p>
                  <div className={Styles.inputTags}>
                    <input
                      placeholder="Author Description"
                      type="text"
                      name="author_desc"
                      onChange={(e) => addBookData(e)}
                      required
                    />
                  </div>
                </div>
                <div className={Styles.tagContanier}>
                  <p>
                    No. of Copies <span className={Styles.asterisk}>*</span> :
                  </p>
                  <div className={Styles.inputTags}>
                    <Button onClick={decrement}>-</Button>
                    <h1>{count}</h1>
                    <Button onClick={increment}>+</Button>
                  </div>
                </div>
                <div className={Styles.tagContanier}>
                  <p>
                    Book Code <span className={Styles.asterisk}>*</span> :
                  </p>
                  <div className={Styles.inputTags}>
                    {[...Array(count)].map((_, index) => (
                      <input
                        key={index}
                        placeholder="Book Code"
                        type="text"
                        name={`book_code_array[${index}]`}
                        onChange={(e) => handleBookCodeChange(e, index)}
                        required
                      />
                    ))}
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => handleAddBook()}
                style={{ background: "red", color: "white" }}
              >
                Update Book
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    </>
  );
};

export default Books;
